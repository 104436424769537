import { getParametriMovimenti, getProfilo, getSaldoComplessivo, parametriMovimenti, profilo, saldoComplessivo, salvaWidgets, setBlurText, widgetSaldo, widgetsUtente } from "@sparkasse/commons";
import BankIcon from "component/Icon/icon.component";
import Psd2Enrollment from "component/psd2/psd2Enrollment.component";
import SelectRapportoMini, { IRapporto } from "component/SelectRapportoMini/SelectRapportoMini";
import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import info from "../../assets/icons/help/help_home.svg";
import { currencyFormat, formatRapporto } from "../../helpers/utilities";
import { AppState } from "../../reducers";

const Balance: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  const {
    blurText,
    saldoComplessivo,
    rapportiNoPSD2,
    widgetSaldo,
    widgetsUtente,
    profilo,
    setBlurText,
    salvaWidgets,
    getProfilo,
    getParametriMovimenti,
    getSaldoComplessivo
  } = props;

  const onHideText = () => {
    setBlurText(!blurText);
  };

  const [tooltipVisible, setTooltipVisible] = useState(false);

  const items: Array<IRapporto> = [{
    id: "0",
    description: `${profilo?.datiUtente?.datiPersonali?.descrizione || ""} - ${t('general.carousel.tuttiIContri')}`
  }].concat(rapportiNoPSD2.rapporti?.map(r => ({
    id: (r.rapporto?.iban || "-1").toString(),
    description: formatRapporto(r)
  })) || []);

  // se un solo rapporto inutile vedere "tutti i conti"
  const rapportoSelezionatoDefault = items.length === 2 ? items[1].id : items[0].id;

  const selezionaConto = (item: IRapporto) => {
    const _widgetSaldo = widgetSaldo ? [{
      ...widgetSaldo, option: {
        toggle: widgetSaldo.option?.toggle,
        interval: widgetSaldo.option?.interval,
        codicerapporto: item.id,
      }
    }] : [];
    salvaWidgets((widgetsUtente?.widgets || []).concat(_widgetSaldo), true);
  };

  const contoSelezionatoCompleto = rapportiNoPSD2?.rapporti?.find(r => r.rapporto?.iban === widgetSaldo?.option?.codicerapporto);
  const saldoContabile = contoSelezionatoCompleto ? contoSelezionatoCompleto?.saldo?.saldoContabile : saldoComplessivo?.saldoContabile;
  const saldoDisponibile = contoSelezionatoCompleto ? contoSelezionatoCompleto?.saldo?.saldoDisponibile : saldoComplessivo?.saldoDisponibile;
  const divisa = contoSelezionatoCompleto?.rapporto?.divisa || saldoComplessivo?.divisa;

  const enrollmentSuccessCallback = () => {
    getParametriMovimenti(true);
    getSaldoComplessivo();
  };

  return (
    <div className="balance-home">
      <span className="corner cornen-saldo-home"></span>

      <SelectRapportoMini
        id="balance"
        items={items}
        selectedItem={contoSelezionatoCompleto?.rapporto?.iban || rapportoSelezionatoDefault}
        onSelect={selezionaConto}
        forceShowSelected
      />


      <div className="complessivo complessivo-contabile">

        <span
          aria-label={`${t('home.balance.saldoContabile')}
           ${blurText ? t("accessibilita.homeBalance.nascosto") : ''}`}
          className="txtCaptionComplessivo">
          {t('home.balance.saldoContabile')}
        </span>

        <div className="viewBalance">

          <span
            aria-label={!blurText ? currencyFormat(saldoContabile) : t("accessibilita.homeBalance.saldoOscurato")}
            aria-hidden={blurText ? "true" : "false"}
            className={`saldo-complessivo-value is-saldo 
              ${saldoContabile != null
                ? Math.sign(saldoContabile) === -1
                  ? 'saldo-negative-color'
                  : 'saldo-positive-color'
                : ''}`}
          >
            {!blurText ? currencyFormat(saldoContabile) : '••••••••'}
            <span
              aria-hidden={blurText ? "true" : "false"}
              className="currency">
              {divisa}
            </span>
          </span>



          <div className="mostra-nascondi-saldo">
            {blurText
              ? <BankIcon
                title="occhio_occhio_aperto"
                red
                className="img-hide-amounts-inline"
                tooltip={t('home.balance.mostraImporti')}
                onClick={() => { onHideText(); }}
                etichetta={t("accessibilita.homeBalance.mostraSaldo")}
              />
              : <BankIcon
                title="occhio_occhio_chiuso"
                red
                className="img-hide-amounts-inline"
                tooltip={t('home.balance.nascondiImporti')}
                onClick={() => { onHideText(); }}
                etichetta={t("accessibilita.homeBalance.nascondiSaldo")}
              />
            }
          </div>

        </div>

      </div>

      <div className="complessivo complessivo-disponibile">

        <span
          aria-label={`${t('home.balance.saldoContabile')}
        ${blurText ? t("accessibilita.homeBalance.nascosto") : ''}`}
          className="txtCaptionDisponibile">
          {t('home.balance.saldoDisponibile')}
        </span>

        <div className="viewBalance">

          <span
            aria-label={!blurText ? currencyFormat(saldoDisponibile) : t("accessibilita.homeBalance.saldoOscurato")}
            aria-hidden={blurText ? "true" : "false"}
            className={`saldo-disponibile-value is-saldo ${saldoDisponibile != null ? Math.sign(saldoDisponibile) === -1 ? 'saldo-negative-color' : 'saldo-positive-color' : ''}`}
          >
            {currencyFormat(saldoDisponibile)}
            <span
              aria-hidden={blurText ? "true" : "false"}
              className="currency"
            >
              {divisa}
            </span>
          </span>

          <span className="tooltip-help-home">
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="button-tooltip" aria-live="assertive">
                  {t("home.balanceTooltip")}
                </Tooltip>
              }
              show={tooltipVisible}
              onToggle={(isOpen) => setTooltipVisible(isOpen)}
            >
              <button
                role="tooltip"
                className="info-tooltip-trigger"
                aria-label={t("home.balanceTooltip")}
                onFocus={() => setTooltipVisible(true)}
                onBlur={() => setTooltipVisible(false)}
                onMouseEnter={() => setTooltipVisible(true)}
                onMouseLeave={() => setTooltipVisible(false)}
              >
                <img className="info-tooltip-icon" src={info} alt="" />
              </button>
            </OverlayTrigger>
          </span>

        </div>

      </div>

      {!!saldoComplessivo?.alertPsd2?.length &&
        <div className="widget-errore-psd2">
          <div className="widget-errore-psd2-icon">
            <BankIcon title="alert" keepSize />
          </div>
          <div className="widget-errore-psd2-testo">
            <span>{t('psd2Attiva.erroreSaldo')}</span>
          </div>
        </div>
      }
      <Psd2Enrollment okCallback={enrollmentSuccessCallback} />

    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  blurText: state.general.blurText,
  saldoComplessivo: saldoComplessivo(state.movimenti),
  rapportiNoPSD2: parametriMovimenti(state.movimenti),
  widgetSaldo: widgetSaldo(state.widgets),
  widgetsUtente: widgetsUtente(state.widgets),
  profilo: profilo(state.profilo),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setBlurText,
      salvaWidgets,
      getProfilo,
      getParametriMovimenti,
      getSaldoComplessivo
    },
    dispatch
  );

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Balance);
