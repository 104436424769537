import { AdeguataVerificaRapportiDto, MessaggioAllegatoDto, MessaggioBodyDto, RapportoADVDto, segnaComeLettoMessaggi, setInizioFlussoCarteInScadenza, setMessaggiSetAccettato } from "@sparkasse/commons";
import ButtonText2 from "component/buttonText2/buttonText2.component";
import { navigateToLogout, navigateToPage, WEBPAGE } from "component/history/history.component";
import { base64ToImageSrc, downloadPDFFromMessaggio } from "helpers/utilities";
import moment from "moment";
import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../reducers";

const MessaggiBloccantiComponent: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const {
    setMessaggiSetAccettato,
    setInizioFlussoCarteInScadenza,
    segnaComeLettoMessaggi,
    messaggio,
    onClose,
    show,
    rapportiAdeguataVerifica,
  } = props;

  const isAdeguataVerifica = messaggio.funzione === "ADEGUATAVERIFICA";
  const isCarteDebitoInScadenza = messaggio.funzione === "CARTEDEBITOINSCADENZA";

  const [terminiCondizioniDownloaded, setTerminiCondizioniDownloaded] = useState(false);

  const onDownloadTerminiCondizioni = (allegato: MessaggioAllegatoDto) => {
    downloadPDFFromMessaggio(allegato);
    setTerminiCondizioniDownloaded(true);
  };

  const accettaTerminiCondizioni = (id: number) => {
    segnaComeLettoMessaggi(id);
    setMessaggiSetAccettato(true);
  };

  const onExit = () => {
    navigateToLogout();
  };

  const onAvvisamiDiNuovo = (id: number) => {
    onClose(id);
  };

  const onRichiediSostituzioneCarte = (id: number) => {
    setInizioFlussoCarteInScadenza(true);
    navigateToPage(WEBPAGE.AcquistoNexiDebit.url);
    onClose(id);
  }

  const navigaDaBloccante = (pagina?: string, id?: number) => {
    id && accettaTerminiCondizioni(id);
    pagina && navigateToPage(pagina);
    onClose(id);
  }

  const getModalBody = (messaggio: MessaggioBodyDto) => {
    switch (messaggio.funzione) {
      case "ADEGUATAVERIFICA":
        return constructRapportiTable(messaggio);
      case "CARTEDEBITOINSCADENZA":
        return constructScadenzaCarte(messaggio);
      default:
        return (
          <>
            {messaggio.immagine?.data &&
              <div className="messaggi-bloccanti-modal__image-container">
                <img
                  src={base64ToImageSrc(messaggio?.immagine?.data)}
                  width={270}
                  alt="" />
              </div>
            }
            <div
              dangerouslySetInnerHTML={{
                __html: messaggio?.body || "<p></p>",
              }}
            />
          </>
        );
    }
  };

  const constructRapportiTable = (messaggio: MessaggioBodyDto) => {
    return (
      <>
        <div dangerouslySetInnerHTML={{
          __html: messaggio && messaggio?.bodyADV ? messaggio?.bodyADV[0] : "<p></p>",
        }} />
        <div>
          <Row className="font-size-16">
            <Col sm={4}><b>{t('messaggiBloccanti.adeguataVerifica.rapporto')}</b></Col>
            <Col sm={{ offset: 2 }}><b>{t('messaggiBloccanti.adeguataVerifica.intestazione')}</b></Col>
            <Col sm={{ offset: 1 }}><b>{t('messaggiBloccanti.adeguataVerifica.giorniMancanti')}</b></Col>
          </Row>
          {rapportiAdeguataVerifica && rapportiAdeguataVerifica.rapportiADV && rapportiAdeguataVerifica.rapportiADV.map((item: RapportoADVDto, i: number) => {
            return (
              <Row key={i} className="rapporti-table-row rapporti-table-text">
                <Col sm={6}>{item.iban}</Col>
                <Col sm={5}>{item.intestatario}</Col>
                <Col sm={1} >{item.giorniResidui}</Col>
              </Row>
            )
          })}
        </div>
        <div dangerouslySetInnerHTML={{
          __html: messaggio && messaggio?.bodyADV ? messaggio?.bodyADV[2] : "",
        }} />
      </>
    );
  };

  const constructScadenzaCarte = (messaggio: MessaggioBodyDto) => {
    return (
      <>
        <div className="messaggi-bloccanti-modal__image-container">
          <img
            src={base64ToImageSrc(messaggio?.immagine?.data)}
            width={200}
            alt="" />
        </div>
        <div className="scadenza-carte-message" dangerouslySetInnerHTML={{
          __html: messaggio.body || "<p></p>",
        }} />
        {messaggio?.listDettaglioCarta?.map((item, i) => {
          return (
            <div key={i} className="scadenza-carte-list">
              <Row>
                <Col xs={6} sm={3} className="bold">{t(`cobadge.messaggiBloccanti.numeroCarta`, { numeroCarta: item.pan?.slice(-8) })}</Col>
                <Col xs={6} sm={3}>{t('cobadge.messaggiBloccanti.contoCollegato', { contoCollegato: item.codiceRapportoContoCollegato })}</Col>
                <Col xs={6} sm={3}>{t('cobadge.messaggiBloccanti.scadenza', { scadenza: moment(item.dataScadenza).format('DD/MM/YYYY') })}</Col>
                <Col xs={6} sm={3} className="text-red">{t('cobadge.messaggiBloccanti.giorniRimanenti', { giorniRimanenti: Math.ceil(((item.dataScadenza?.getTime() || new Date().getTime()) - new Date().getTime()) / (1000 * 60 * 60 * 24)) })}</Col>
              </Row>
            </div>
          );
        })}
        <div className="scadenza-carte-message mt-3">
          <p className="italic">{t('cobadge.messaggiBloccanti.footerMessage')}</p>
        </div>
      </>
    );
  }

  const backdrop = messaggio.impedisciAccesso ? 'static' : true;

  return (
    <Modal show={show} backdrop={backdrop} className={`mesaggi-bloccanti-modal ${messaggio.funzione == 'CARTEDEBITOINSCADENZA' ? 'modale-cobadge' : ''}`} onHide={() => onClose(messaggio.idMessaggio)}>

      <Modal.Header>
        <Modal.Title>{messaggio.titolo}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{getModalBody(messaggio)}</Modal.Body>

      {!!messaggio.allegato && (
        <ButtonText2
          descrizione={messaggio.allegato.name || t("messaggiBloccanti.button.downloadPDF")}
          onClick={() => onDownloadTerminiCondizioni(messaggio.allegato!)}
          imageOnLeft={true}
          img="azioni.esporta_pdf"
        />
      )}

      <Modal.Footer className="justify">

        { /* AVVISAMI DI NUOVO BUTTON  */}
        {(!!messaggio.showBtnMostraAllaProssimaVolta && !messaggio.impedisciAccesso && !isAdeguataVerifica) && (
          <button
            type="button"
            className="mesaggi-bloccanti-modal__close-button empty_secondary_button"
            onClick={() => onAvvisamiDiNuovo(messaggio.idMessaggio!)}
          >
            {t("messaggiBloccanti.button.avvisamiDiNuovo").toUpperCase()}
          </button>
        )}

        {/* EXIT BUTTON  */}
        {((!messaggio.showBtnMostraAllaProssimaVolta &&
          !messaggio.impedisciAccesso) ||
          messaggio.impedisciAccesso) && (
            <button
              type="button"
              className="mesaggi-bloccanti-modal__close-button empty_secondary_button"
              onClick={onExit}
            >
              {t("messaggiBloccanti.button.esci").toUpperCase()}
            </button>
          )}

        {/* OK BUTTON */}
        {!messaggio.impedisciAccesso && !isCarteDebitoInScadenza && !messaggio.deepLinkValue && (
          <button
            type="button"
            className={"mesaggi-bloccanti-modal__close-button filled_primary_button ml-auto general-button " + ((messaggio.allegato && !terminiCondizioniDownloaded) ? 'general-button-disabled' : '')}
            onClick={() => isAdeguataVerifica ? onAvvisamiDiNuovo(messaggio.idMessaggio!) : accettaTerminiCondizioni(messaggio.idMessaggio!)}
            disabled={messaggio.allegato ? !terminiCondizioniDownloaded : false}
          >
            {t("messaggiBloccanti.button.ok").toUpperCase()}
          </button>
        )}
        {/* RICHIEDI ORA BUTTON */}
        {!messaggio.impedisciAccesso && isCarteDebitoInScadenza && !messaggio.deepLinkValue && (
          <button
            type="button"
            className={"mesaggi-bloccanti-modal__close-button filled_primary_button ml-auto general-button "}
            onClick={() => onRichiediSostituzioneCarte(messaggio.idMessaggio!)}
            disabled={messaggio?.listDettaglioCarta?.length === 0}
          >
            {t("messaggiBloccanti.button.richiediOra").toUpperCase()}
          </button>
        )}
        {/* NAVIGAZIONE BUTTON */}
        {!messaggio.impedisciAccesso && !isCarteDebitoInScadenza && !!messaggio.deepLinkValue && (
          <button
            type="button"
            className={"mesaggi-bloccanti-modal__close-button filled_primary_button ml-auto general-button "}
            onClick={() => navigaDaBloccante(messaggio.deepLinkValue, messaggio.idMessaggio)}
          >
            {t("messaggiBloccanti.button.procedi").toUpperCase()}
          </button>
        )}

      </Modal.Footer>
    </Modal>
  );
};

interface MessaggiBloccantiProps {
  messaggio: MessaggioBodyDto
  onClose: (id: number | undefined) => void
  show: boolean
  rapportiAdeguataVerifica?: AdeguataVerificaRapportiDto
}

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      segnaComeLettoMessaggi,
      setMessaggiSetAccettato,
      setInizioFlussoCarteInScadenza
    },
    dispatch
  );

export type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & MessaggiBloccantiProps;

export default connect(mapStateToProps, mapDispatchToProps)(MessaggiBloccantiComponent);
