"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DettaglioCartaBodyDtoToJSON = exports.DettaglioCartaBodyDtoFromJSONTyped = exports.DettaglioCartaBodyDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DettaglioCartaBodyDtoFromJSON(json) {
    return DettaglioCartaBodyDtoFromJSONTyped(json, false);
}
exports.DettaglioCartaBodyDtoFromJSON = DettaglioCartaBodyDtoFromJSON;
function DettaglioCartaBodyDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'pan': !runtime_1.exists(json, 'pan') ? undefined : json['pan'],
        'dataAttivazione': !runtime_1.exists(json, 'dataAttivazione') ? undefined : (new Date(json['dataAttivazione'])),
        'dataEstinzione': !runtime_1.exists(json, 'dataEstinzione') ? undefined : (new Date(json['dataEstinzione'])),
        'idDispReale': !runtime_1.exists(json, 'idDispReale') ? undefined : json['idDispReale'],
        'codiceProdotto': !runtime_1.exists(json, 'codiceProdotto') ? undefined : json['codiceProdotto'],
        'releaseProdotto': !runtime_1.exists(json, 'releaseProdotto') ? undefined : json['releaseProdotto'],
        'dataScadenza': !runtime_1.exists(json, 'dataScadenza') ? undefined : (new Date(json['dataScadenza'])),
        'tipologiaCarta': !runtime_1.exists(json, 'tipologiaCarta') ? undefined : json['tipologiaCarta'],
        'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
        'codiceRapportoContoCollegato': !runtime_1.exists(json, 'codiceRapportoContoCollegato') ? undefined : json['codiceRapportoContoCollegato'],
    };
}
exports.DettaglioCartaBodyDtoFromJSONTyped = DettaglioCartaBodyDtoFromJSONTyped;
function DettaglioCartaBodyDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'pan': value.pan,
        'dataAttivazione': value.dataAttivazione === undefined ? undefined : (value.dataAttivazione.toISOString()),
        'dataEstinzione': value.dataEstinzione === undefined ? undefined : (value.dataEstinzione.toISOString()),
        'idDispReale': value.idDispReale,
        'codiceProdotto': value.codiceProdotto,
        'releaseProdotto': value.releaseProdotto,
        'dataScadenza': value.dataScadenza === undefined ? undefined : (value.dataScadenza.toISOString()),
        'tipologiaCarta': value.tipologiaCarta,
        'codiceRapporto': value.codiceRapporto,
        'codiceRapportoContoCollegato': value.codiceRapportoContoCollegato,
    };
}
exports.DettaglioCartaBodyDtoToJSON = DettaglioCartaBodyDtoToJSON;
