"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NexiDebitScaduteDtoToJSON = exports.NexiDebitScaduteDtoFromJSONTyped = exports.NexiDebitScaduteDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function NexiDebitScaduteDtoFromJSON(json) {
    return NexiDebitScaduteDtoFromJSONTyped(json, false);
}
exports.NexiDebitScaduteDtoFromJSON = NexiDebitScaduteDtoFromJSON;
function NexiDebitScaduteDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'carteScadute': !runtime_1.exists(json, 'carteScadute') ? undefined : (json['carteScadute'].map(_1.DettaglioCartaBodyDtoFromJSON)),
        'valid': !runtime_1.exists(json, 'valid') ? undefined : json['valid'],
        'motivation': !runtime_1.exists(json, 'motivation') ? undefined : json['motivation'],
        'instruction': !runtime_1.exists(json, 'instruction') ? undefined : json['instruction'],
    };
}
exports.NexiDebitScaduteDtoFromJSONTyped = NexiDebitScaduteDtoFromJSONTyped;
function NexiDebitScaduteDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'carteScadute': value.carteScadute === undefined ? undefined : (value.carteScadute.map(_1.DettaglioCartaBodyDtoToJSON)),
        'valid': value.valid,
        'motivation': value.motivation,
        'instruction': value.instruction,
    };
}
exports.NexiDebitScaduteDtoToJSON = NexiDebitScaduteDtoToJSON;
