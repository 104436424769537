"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ControlloAcquistoRequestDtoToJSON = exports.ControlloAcquistoRequestDtoFromJSONTyped = exports.ControlloAcquistoRequestDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ControlloAcquistoRequestDtoFromJSON(json) {
    return ControlloAcquistoRequestDtoFromJSONTyped(json, false);
}
exports.ControlloAcquistoRequestDtoFromJSON = ControlloAcquistoRequestDtoFromJSON;
function ControlloAcquistoRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'professione': !runtime_1.exists(json, 'professione') ? undefined : json['professione'],
        'tae': !runtime_1.exists(json, 'tae') ? undefined : json['tae'],
        'personaPoliticamenteEsposta': !runtime_1.exists(json, 'personaPoliticamenteEsposta') ? undefined : json['personaPoliticamenteEsposta'],
        'tipoCarta': !runtime_1.exists(json, 'tipoCarta') ? undefined : json['tipoCarta'],
        'provenienzaFondi': !runtime_1.exists(json, 'provenienzaFondi') ? undefined : (json['provenienzaFondi'].map(_1.ProvenienzaFondiReqDtoFromJSON)),
        'scopoPrevalente': !runtime_1.exists(json, 'scopoPrevalente') ? undefined : json['scopoPrevalente'],
        'consensoResponseList': !runtime_1.exists(json, 'consensoResponseList') ? undefined : (json['consensoResponseList'].map(_1.ConsensoResponseFromJSON)),
        'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
        'flussoCoBadged': !runtime_1.exists(json, 'flussoCoBadged') ? undefined : json['flussoCoBadged'],
        'codiceRapportoCoBadged': !runtime_1.exists(json, 'codiceRapportoCoBadged') ? undefined : json['codiceRapportoCoBadged'],
        'pan': !runtime_1.exists(json, 'pan') ? undefined : json['pan'],
    };
}
exports.ControlloAcquistoRequestDtoFromJSONTyped = ControlloAcquistoRequestDtoFromJSONTyped;
function ControlloAcquistoRequestDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'professione': value.professione,
        'tae': value.tae,
        'personaPoliticamenteEsposta': value.personaPoliticamenteEsposta,
        'tipoCarta': value.tipoCarta,
        'provenienzaFondi': value.provenienzaFondi === undefined ? undefined : (value.provenienzaFondi.map(_1.ProvenienzaFondiReqDtoToJSON)),
        'scopoPrevalente': value.scopoPrevalente,
        'consensoResponseList': value.consensoResponseList === undefined ? undefined : (value.consensoResponseList.map(_1.ConsensoResponseToJSON)),
        'codiceRapporto': value.codiceRapporto,
        'flussoCoBadged': value.flussoCoBadged,
        'codiceRapportoCoBadged': value.codiceRapportoCoBadged,
        'pan': value.pan,
    };
}
exports.ControlloAcquistoRequestDtoToJSON = ControlloAcquistoRequestDtoToJSON;
