import { bloccantiMessaggi, myBankTokenSelector } from "@sparkasse/commons";
import corner from "assets/icons/random/mirino.svg";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { WEBPAGE } from "../../component/history/history.component";
import { AppState } from "../../reducers";

const PostLoginMessages: React.FC<Props> = (props: Props) => {

  const {
    history,
    bloccantiMessaggi,
    myBankToken,
  } = props;

  useEffect(() => {

    if (bloccantiMessaggi && bloccantiMessaggi.length === 0) {

      if (myBankToken) {
        history.push(WEBPAGE.BonificoMyBank.url);
      } else {
        history.push(WEBPAGE.Home.url);
      }

    }

  }, [bloccantiMessaggi, myBankToken]);

  return (
    <div className="wide">
      <div className="main">
        <div className="core">
          <div className={"gray-box"}>
            <div className={"first-row mb-5"}>
              <img
                src={corner}
                style={{
                  height: 30,
                  position: "relative",
                  left: 25,
                  top: 110,
                }}
                alt=""
                aria-hidden={true}
              />
              <div className={"box-header"}></div>
              <div className={"box-header"}></div>
              <div className={"box-header"}></div>
              <div className={"box-header"}></div>
              <div className={"box-header"}></div>
              <div className={"box-header"}></div>
            </div>
            <div className={"second-row"}>
              <div className={"col-sm-8 box-left"}>
                <div className={"box-left-body"}></div>
                <div className={"box-left-body-line-1"}></div>
                <div className={"box-left-body-line-2"}></div>
                <div className={"box-left-body-line-3"}></div>
                <div className={"box-left-body-line-3"}></div>
                <div className={"box-left-body-line-3"}></div>
                <div className={"box-left-body-line-3"}></div>
                <div className={"box-left-body-line-3"}></div>
                <div className={"row"}>
                  <div className={"col"}>
                    <div className={"box-space-between "}></div>
                  </div>
                  <div className={"col"}>
                    <div className={"box-space-between right"}></div>
                  </div>
                </div>
              </div>
              <div className={"col-sm-4"}>
                <div className={"box-right-body"}></div>
              </div>
            </div>
            <div className={"third-row"}>
              <div className={"col-sm-2"}>
                <div className={"box"}></div>
              </div>
              <div className={"col-sm-10"}>
                <div className={"box-left-body-line-1"}></div>
                <div className={"box-left-body-line-2"}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  bloccantiMessaggi: bloccantiMessaggi(state.messaggi),
  myBankToken: myBankTokenSelector(state.myBank),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {},
    dispatch
  );

interface RouteParams {
  id: string
}

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<RouteParams>;

export default connect(mapStateToProps, mapDispatchToProps)(PostLoginMessages);
