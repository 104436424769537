"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ElencoMovimentiPdfNexiDebitTypeEnum = exports.NexiDebitControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class NexiDebitControllerApi extends runtime.BaseAPI {
    /**
     * Annulla l\'ultima pratica di acquisto aperta dal cliente
     */
    annullaAcquistoNexiDebitRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.annulloPraticaAcquistoDto === null || requestParameters.annulloPraticaAcquistoDto === undefined) {
                throw new runtime.RequiredError('annulloPraticaAcquistoDto', 'Required parameter requestParameters.annulloPraticaAcquistoDto was null or undefined when calling annullaAcquistoNexiDebit.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/nexidebit/acquisto/annulla`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.AnnulloPraticaAcquistoDtoToJSON(requestParameters.annulloPraticaAcquistoDto),
            });
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Annulla l\'ultima pratica di acquisto aperta dal cliente
     */
    annullaAcquistoNexiDebit(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.annullaAcquistoNexiDebitRaw(requestParameters);
        });
    }
    /**
     */
    checkUserAcquistoNexiDebitRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/nexidebit/acquisto/check-user`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.CheckUserResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    checkUserAcquistoNexiDebit() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.checkUserAcquistoNexiDebitRaw();
            return yield response.value();
        });
    }
    /**
     * Conclude negativamente l\'ultima pratica di acquisto aperta dal cliente e in stato rifiutata
     */
    concludiNegativamenteAcquistoNexiDebitRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.annulloPraticaAcquistoDto === null || requestParameters.annulloPraticaAcquistoDto === undefined) {
                throw new runtime.RequiredError('annulloPraticaAcquistoDto', 'Required parameter requestParameters.annulloPraticaAcquistoDto was null or undefined when calling concludiNegativamenteAcquistoNexiDebit.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/nexidebit/acquisto/concludi-negativamente`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.AnnulloPraticaAcquistoDtoToJSON(requestParameters.annulloPraticaAcquistoDto),
            });
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Conclude negativamente l\'ultima pratica di acquisto aperta dal cliente e in stato rifiutata
     */
    concludiNegativamenteAcquistoNexiDebit(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.concludiNegativamenteAcquistoNexiDebitRaw(requestParameters);
        });
    }
    /**
     */
    controlloAcquistoNexiDebitRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.controlloAcquistoRequestDto === null || requestParameters.controlloAcquistoRequestDto === undefined) {
                throw new runtime.RequiredError('controlloAcquistoRequestDto', 'Required parameter requestParameters.controlloAcquistoRequestDto was null or undefined when calling controlloAcquistoNexiDebit.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/nexidebit/acquisto/controllo`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ControlloAcquistoRequestDtoToJSON(requestParameters.controlloAcquistoRequestDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.DatiCartaAcquistoDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    controlloAcquistoNexiDebit(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.controlloAcquistoNexiDebitRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce la convenzione per il rapporto passato in path
     */
    convenzioneRapportoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.idRapporto === null || requestParameters.idRapporto === undefined) {
                throw new runtime.RequiredError('idRapporto', 'Required parameter requestParameters.idRapporto was null or undefined when calling convenzioneRapporto.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/nexidebit/acquisto/convenzioni/{idRapporto}`.replace(`{${"idRapporto"}}`, encodeURIComponent(String(requestParameters.idRapporto))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ConvenzioneRapportoResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce la convenzione per il rapporto passato in path
     */
    convenzioneRapporto(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.convenzioneRapportoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Export in pdf del dettaglio del movimento in base ai criteri inviati per il rapporto indicato
     */
    dettaglioMovimentiPdfNexiDebitRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.movimentoNexiDebitDto === null || requestParameters.movimentoNexiDebitDto === undefined) {
                throw new runtime.RequiredError('movimentoNexiDebitDto', 'Required parameter requestParameters.movimentoNexiDebitDto was null or undefined when calling dettaglioMovimentiPdfNexiDebit.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/nexidebit/export/pdf`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.MovimentoNexiDebitDtoToJSON(requestParameters.movimentoNexiDebitDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Export in pdf del dettaglio del movimento in base ai criteri inviati per il rapporto indicato
     */
    dettaglioMovimentiPdfNexiDebit(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.dettaglioMovimentiPdfNexiDebitRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Export in pdf o excel dei movimenti in base ai criteri inviati per il rapporto indicato
     */
    elencoMovimentiPdfNexiDebitRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.type === null || requestParameters.type === undefined) {
                throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling elencoMovimentiPdfNexiDebit.');
            }
            if (requestParameters.criteriRicercaMovimentiNexiDebitDto === null || requestParameters.criteriRicercaMovimentiNexiDebitDto === undefined) {
                throw new runtime.RequiredError('criteriRicercaMovimentiNexiDebitDto', 'Required parameter requestParameters.criteriRicercaMovimentiNexiDebitDto was null or undefined when calling elencoMovimentiPdfNexiDebit.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/nexidebit/movimenti/elenco/export/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CriteriRicercaMovimentiNexiDebitDtoToJSON(requestParameters.criteriRicercaMovimentiNexiDebitDto),
            });
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Export in pdf o excel dei movimenti in base ai criteri inviati per il rapporto indicato
     */
    elencoMovimentiPdfNexiDebit(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.elencoMovimentiPdfNexiDebitRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Recupera il PAN della carta
     */
    getEncryptedNexiDebitPanViewRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.body === null || requestParameters.body === undefined) {
                throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling getEncryptedNexiDebitPanView.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/nexidebit/pan`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters.body,
            });
            return new runtime.TextApiResponse(response);
        });
    }
    /**
     * Recupera il PAN della carta
     */
    getEncryptedNexiDebitPanView(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getEncryptedNexiDebitPanViewRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Recupera nexi debit in scadenza
     */
    getNexiDebitScaduteRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/nexidebit/acquisto/scadute`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.NexiDebitScaduteDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera nexi debit in scadenza
     */
    getNexiDebitScadute() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getNexiDebitScaduteRaw();
            return yield response.value();
        });
    }
    /**
     * Recupera il PAN della carta
     */
    getPanViewRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.txId === null || requestParameters.txId === undefined) {
                throw new runtime.RequiredError('txId', 'Required parameter requestParameters.txId was null or undefined when calling getPanView.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/nexidebit/getPanView/{txId}`.replace(`{${"txId"}}`, encodeURIComponent(String(requestParameters.txId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.PanViewResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera il PAN della carta
     */
    getPanView(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getPanViewRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Recupera il PIN della carta
     */
    getPinViewRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.txId === null || requestParameters.txId === undefined) {
                throw new runtime.RequiredError('txId', 'Required parameter requestParameters.txId was null or undefined when calling getPinView.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/nexidebit/getPinView/{txId}`.replace(`{${"txId"}}`, encodeURIComponent(String(requestParameters.txId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.PinViewResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera il PIN della carta
     */
    getPinView(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getPinViewRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Movimenti nexi debit
     */
    movimentiNexiDebitRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.criteriRicercaMovimentiNexiDebitDto === null || requestParameters.criteriRicercaMovimentiNexiDebitDto === undefined) {
                throw new runtime.RequiredError('criteriRicercaMovimentiNexiDebitDto', 'Required parameter requestParameters.criteriRicercaMovimentiNexiDebitDto was null or undefined when calling movimentiNexiDebit.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/nexidebit/movimenti/elenco`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.CriteriRicercaMovimentiNexiDebitDtoToJSON(requestParameters.criteriRicercaMovimentiNexiDebitDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ListaMovimentiNexiDebitFromJSON(jsonValue));
        });
    }
    /**
     * Movimenti nexi debit
     */
    movimentiNexiDebit(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.movimentiNexiDebitRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Restituisce i parametri per l\'acquisto nexi
     */
    parametriAcquistoNexiDebitRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.flussoCoBadged !== undefined && requestParameters.flussoCoBadged !== null) {
                headerParameters['flussoCoBadged'] = String(requestParameters.flussoCoBadged);
            }
            if (requestParameters.codiceRapportoContoCollegato !== undefined && requestParameters.codiceRapportoContoCollegato !== null) {
                headerParameters['codiceRapportoContoCollegato'] = String(requestParameters.codiceRapportoContoCollegato);
            }
            if (requestParameters.codiceRapporto !== undefined && requestParameters.codiceRapporto !== null) {
                headerParameters['codiceRapporto'] = String(requestParameters.codiceRapporto);
            }
            const response = yield this.request({
                path: `/private/nexidebit/acquisto/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriAcquistoNexiDebitResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Restituisce i parametri per l\'acquisto nexi
     */
    parametriAcquistoNexiDebit(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriAcquistoNexiDebitRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Lettura parametri Nexi
     */
    parametriNexiDebitRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/private/nexidebit/parametri`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ParametriNexiDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lettura parametri Nexi
     */
    parametriNexiDebit() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.parametriNexiDebitRaw();
            return yield response.value();
        });
    }
    /**
     * Inizializza l\'operazione di SCA per la visualizzazione del PAN della carta
     */
    startPanViewSCARaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.startPanViewSCARequestDto === null || requestParameters.startPanViewSCARequestDto === undefined) {
                throw new runtime.RequiredError('startPanViewSCARequestDto', 'Required parameter requestParameters.startPanViewSCARequestDto was null or undefined when calling startPanViewSCA.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/nexidebit/startPanViewSCA`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.StartPanViewSCARequestDtoToJSON(requestParameters.startPanViewSCARequestDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.StartPanViewSCAResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Inizializza l\'operazione di SCA per la visualizzazione del PAN della carta
     */
    startPanViewSCA(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.startPanViewSCARaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Inizializza l\'operazione di SCA per la visualizzazione del PIN della carta
     */
    startPinViewSCARaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.startPinViewSCARequestDto === null || requestParameters.startPinViewSCARequestDto === undefined) {
                throw new runtime.RequiredError('startPinViewSCARequestDto', 'Required parameter requestParameters.startPinViewSCARequestDto was null or undefined when calling startPinViewSCA.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/private/nexidebit/startPinViewSCA`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.StartPinViewSCARequestDtoToJSON(requestParameters.startPinViewSCARequestDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.StartPinViewSCAResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Inizializza l\'operazione di SCA per la visualizzazione del PIN della carta
     */
    startPinViewSCA(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.startPinViewSCARaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.NexiDebitControllerApi = NexiDebitControllerApi;
/**
    * @export
    * @enum {string}
    */
var ElencoMovimentiPdfNexiDebitTypeEnum;
(function (ElencoMovimentiPdfNexiDebitTypeEnum) {
    ElencoMovimentiPdfNexiDebitTypeEnum["pdf"] = "pdf";
    ElencoMovimentiPdfNexiDebitTypeEnum["excel"] = "excel";
})(ElencoMovimentiPdfNexiDebitTypeEnum = exports.ElencoMovimentiPdfNexiDebitTypeEnum || (exports.ElencoMovimentiPdfNexiDebitTypeEnum = {}));
