"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPaymentPollingActive = exports.setPaymentPollingActiveAction = exports.resetProcessoFirmaDigitale = exports.creaCertificatoFirmaDigitale = exports.verificaFirmaDigitale = exports.enrollmentDownloadPdfEnrollmentPSD2 = exports.enrollmentPSD2Reset = exports.enrollmentSetFormSubmitted = exports.enrollmentUpdateIbanEnrollmentPSD2 = exports.enrollmentRemoveIbanEnrollmentPSD2 = exports.enrollmentAddIbanEnrollmentPSD2 = exports.enrollmentSetConsensoEnrollmentPSD2 = exports.enrollmentSetProdottoEnrollmentPSD2 = exports.enrollmentSetUsernameEnrollmentPSD2 = exports.enrollmentSetBancaSelezionataEnrollmentPSD2 = exports.enrollmentSetStepEnrollmentPSD2 = exports.enrollmentPSD2ResetForm = exports.psd2ResetLinks = exports.enrollmentStatusPSD2 = exports.enrollmentStatusPSD2FromConsentRenew = exports.enrollmentActivitiesPSD2 = exports.startEnrollmentPSD2 = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
const psd2_types_1 = require("./psd2.types");
function generalError(value, showGenericModalError) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value, showGenericModalError));
    };
}
/* START ENROLLMENT - INIZIO */
function startEnrollmentPSD2Start() {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_START_ENROLLMENT_START
    };
}
function startEnrollmentPSD2Success(response) {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_START_ENROLLMENT_SUCCESS,
        payload: response
    };
}
function startEnrollmentPSD2Error() {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_START_ENROLLMENT_ERROR
    };
}
const startEnrollmentPSD2 = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(startEnrollmentPSD2Start());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/enrollment-psd2-start.json')).then(listaBanche => {
            dispatch(startEnrollmentPSD2Success((0, api_rest_1.AspspOutDtoFromJSON)(listaBanche)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.Psd2AttivaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).aspspList().then((response) => {
                dispatch(startEnrollmentPSD2Success(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
                dispatch(startEnrollmentPSD2Error());
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.startEnrollmentPSD2 = startEnrollmentPSD2;
/* START ENROLLMENT - FINE */
/* ENROLLMENT ACTIVITIES - INIZIO */
function enrollmentActivitiesPSD2Start() {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_ENROLLMENT_ACTIVITIES_START
    };
}
function enrollmentActivitiesPSD2Success(response) {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_ENROLLMENT_ACTIVITIES_SUCCESS,
        payload: response
    };
}
function enrollmentActivitiesPSD2Error() {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_ENROLLMENT_ACTIVITIES_ERROR
    };
}
const enrollmentActivitiesPSD2 = (aspspId) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(enrollmentActivitiesPSD2Start());
    if (extraArguments.mock) {
        Promise.resolve().then(() => require('../../mock/enrollment-psd2-activities.json')).then(activities => {
            dispatch(enrollmentActivitiesPSD2Success((0, api_rest_1.EnrollmentActivityDtoFromJSON)(activities)));
            dispatch((0, general_actions_1.setLoader)(false));
        });
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            var _a;
            const statePSD2 = getState().psd2;
            const request = {
                aspspFilterIn: {
                    aspspId: !!aspspId ? aspspId : (_a = statePSD2.formEnrollment.banca) === null || _a === void 0 ? void 0 : _a.aspspId,
                }
            };
            new api_rest_1.Psd2AttivaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).chechEnrollmentActivity(request).then((response) => {
                dispatch(enrollmentActivitiesPSD2Success(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
                dispatch(enrollmentActivitiesPSD2Error());
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.enrollmentActivitiesPSD2 = enrollmentActivitiesPSD2;
/* ENROLLMENT ACTIVITIES - FINE */
/* ENROLLMENT STATUS - INIZIO */
function enrollmentStatusPSD2Start() {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_ENROLLMENT_STATUS_START
    };
}
function enrollmentStatusPSD2Success(response) {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_ENROLLMENT_STATUS_SUCCESS,
        payload: response
    };
}
function enrollmentStatusPSD2WebViewLink(link) {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_ENROLLMENT_STATUS_WEBVIEW_LINK,
        payload: link
    };
}
function enrollmentStatusPSD2Error() {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_ENROLLMENT_STATUS_ERROR
    };
}
const enrollmentStatusPSD2FromConsentRenew = (consentStatus) => async (dispatch, getState, extraArguments) => {
    var _a, _b;
    const stateElencoRapporti = getState().elencoRapporti;
    dispatch((0, exports.enrollmentStatusPSD2)(undefined, {
        aspspId: (_a = stateElencoRapporti.form.rapportoSelezionato.psd2) === null || _a === void 0 ? void 0 : _a.aspspId,
        aspspProductCode: (_b = stateElencoRapporti.form.rapportoSelezionato.psd2) === null || _b === void 0 ? void 0 : _b.aspspProductCode,
    }));
};
exports.enrollmentStatusPSD2FromConsentRenew = enrollmentStatusPSD2FromConsentRenew;
function setEnrollmentPollingActive() {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_ENROLLMENT_POLLING_SET_ACTIVE
    };
}
function setPSD2ResponseStatus(response) {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_SET_RESPONSE_STATUS,
        payload: response
    };
}
const enrollmentStatusPSD2 = (consentStatus, enrollmentStatusInDto) => async (dispatch, getState, extraArguments) => {
    const statePSD2 = getState().psd2;
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(enrollmentStatusPSD2Start());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
            const ibanList = (_a = statePSD2.formEnrollment.ibanList) === null || _a === void 0 ? void 0 : _a.filter(iban => { var _a; return iban.iban && ((_a = iban.iban) === null || _a === void 0 ? void 0 : _a.length) > 0; });
            const psd2Consent = (_c = (_b = statePSD2.psd2ResponseStatus) === null || _b === void 0 ? void 0 : _b.psd2Response) === null || _c === void 0 ? void 0 : _c.activatedConsent;
            const oneShot = (_e = (_d = statePSD2.psd2ResponseStatus) === null || _d === void 0 ? void 0 : _d.psd2Response) === null || _e === void 0 ? void 0 : _e.oneShot;
            const link = (_h = (_g = (_f = statePSD2.psd2ResponseStatus) === null || _f === void 0 ? void 0 : _f.psd2Response) === null || _g === void 0 ? void 0 : _g.accountList) === null || _h === void 0 ? void 0 : _h.link;
            const accounts = (_l = (_k = (_j = statePSD2.psd2ResponseStatus) === null || _j === void 0 ? void 0 : _j.psd2Response) === null || _k === void 0 ? void 0 : _k.accountList) === null || _l === void 0 ? void 0 : _l.accounts;
            const request = {
                enrollmentStatusInDto: enrollmentStatusInDto || {
                    aspspId: (_m = statePSD2.formEnrollment.banca) === null || _m === void 0 ? void 0 : _m.aspspId,
                    aspspProductCode: (_o = statePSD2.formEnrollment.prodotto) === null || _o === void 0 ? void 0 : _o.aspspProductCode,
                    activeConsent: true,
                    ibanList: ibanList.length > 0 ? ibanList.map(iban => iban.iban || '') : undefined,
                    providerId: (_r = (_q = (_p = statePSD2.dettaglioBanca) === null || _p === void 0 ? void 0 : _p.listDetail) === null || _q === void 0 ? void 0 : _q[0].aspspProductList) === null || _r === void 0 ? void 0 : _r[0].providerId,
                    consentStatus: consentStatus,
                }
            };
            const requestDue = {
                enrollmentStatusInDto: enrollmentStatusInDto || {
                    aspspId: (_s = statePSD2.formEnrollment.banca) === null || _s === void 0 ? void 0 : _s.aspspId,
                    aspspProductCode: (_t = statePSD2.formEnrollment.prodotto) === null || _t === void 0 ? void 0 : _t.aspspProductCode,
                    activeConsent: (psd2Consent && oneShot) || (psd2Consent && !oneShot) ? false : true,
                    ibanList: ibanList.length > 0 ? ibanList.map(iban => iban.iban || '') : undefined,
                    providerId: (_w = (_v = (_u = statePSD2.dettaglioBanca) === null || _u === void 0 ? void 0 : _u.listDetail) === null || _v === void 0 ? void 0 : _v[0].aspspProductList) === null || _w === void 0 ? void 0 : _w[0].providerId,
                    consentStatus: consentStatus,
                    consentId: (_y = (_x = statePSD2.psd2ResponseStatus) === null || _x === void 0 ? void 0 : _x.psd2Response) === null || _y === void 0 ? void 0 : _y.consentId,
                    consentType: (_0 = (_z = statePSD2.psd2ResponseStatus) === null || _z === void 0 ? void 0 : _z.psd2Response) === null || _0 === void 0 ? void 0 : _0.consentType,
                }
            };
            new api_rest_1.Psd2AttivaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).chechEnrollmentStatus(psd2Consent || (psd2Consent && !oneShot) || (!link && !!accounts && (accounts === null || accounts === void 0 ? void 0 : accounts.length) > 0) ? requestDue : request).then((response) => {
                var _a, _b, _c;
                if ((_a = response.accountList) === null || _a === void 0 ? void 0 : _a.link) {
                    // Intercettiamo la joburl del 2° o più consensi
                    dispatch(enrollmentStatusPSD2WebViewLink(response.accountList.link));
                    dispatch(setPSD2ResponseStatus(response));
                }
                else if ((_c = (_b = statePSD2.psd2ResponseStatus) === null || _b === void 0 ? void 0 : _b.psd2Response) === null || _c === void 0 ? void 0 : _c.oneShot) {
                    //Mi aspetto una seconda SCA
                    dispatch(setPSD2ResponseStatus(response));
                }
                else {
                    // Tutti i consensi sono stati dati
                    dispatch((0, general_actions_1.enrollmentPSD2AttivaSuccess)()); // serve per resettare tutte le funzionalità in cui vengono utilizzati i rapporti PSD2
                    dispatch(enrollmentStatusPSD2Success(response));
                    dispatch(setPSD2ResponseStatus({}));
                }
            }).catch(async (error) => {
                var _a, _b;
                const decodedError = await (0, helpers_1.decodeError)(error);
                if ((_b = (_a = decodedError === null || decodedError === void 0 ? void 0 : decodedError._links) === null || _a === void 0 ? void 0 : _a.url) === null || _b === void 0 ? void 0 : _b.href) {
                    // Intercettiamo la joburl del 1° consenso
                    dispatch(enrollmentStatusPSD2WebViewLink(decodedError._links.url.href));
                }
                else if ((decodedError === null || decodedError === void 0 ? void 0 : decodedError.details) == 'PSD2.DECOUPLED.AUTH.WAIT') {
                    // Enrollment decoupled: dobbiamo metterci in polling con il FE e visualizzare un messaggio perchè l'utente deve autorizzare dall'app della banca l'autenticazione
                    dispatch(setEnrollmentPollingActive());
                }
                else if ((decodedError === null || decodedError === void 0 ? void 0 : decodedError.message) == 'AUTH_DECOUPLED') {
                    dispatch((0, exports.enrollmentStatusPSD2)('received'));
                }
                else {
                    dispatch(generalError(await (0, helpers_1.formatError)(decodedError, false, true)));
                    dispatch(enrollmentStatusPSD2Error());
                }
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.enrollmentStatusPSD2 = enrollmentStatusPSD2;
/* ENROLLMENT STATUS - FINE */
function psd2ResetLinks() {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_RESET_LINKS
    };
}
exports.psd2ResetLinks = psd2ResetLinks;
function enrollmentPSD2ResetForm() {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_RESET_FORM_ENROLLMENT
    };
}
exports.enrollmentPSD2ResetForm = enrollmentPSD2ResetForm;
function enrollmentSetStepEnrollmentPSD2(step) {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_SET_STEP_ENROLLMENT,
        payload: step
    };
}
exports.enrollmentSetStepEnrollmentPSD2 = enrollmentSetStepEnrollmentPSD2;
function enrollmentSetBancaSelezionataEnrollmentPSD2(banca) {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_SET_BANCA_SELEZIONATA_ENROLLMENT,
        payload: banca
    };
}
exports.enrollmentSetBancaSelezionataEnrollmentPSD2 = enrollmentSetBancaSelezionataEnrollmentPSD2;
function enrollmentSetUsernameEnrollmentPSD2(username) {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_SET_USERNAME_ENROLLMENT,
        payload: username
    };
}
exports.enrollmentSetUsernameEnrollmentPSD2 = enrollmentSetUsernameEnrollmentPSD2;
function enrollmentSetProdottoEnrollmentPSD2(prodotto) {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_SET_PRODOTTO_ENROLLMENT,
        payload: prodotto
    };
}
exports.enrollmentSetProdottoEnrollmentPSD2 = enrollmentSetProdottoEnrollmentPSD2;
function enrollmentSetConsensoEnrollmentPSD2(consenso) {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_SET_CONSENSO_ENROLLMENT,
        payload: consenso
    };
}
exports.enrollmentSetConsensoEnrollmentPSD2 = enrollmentSetConsensoEnrollmentPSD2;
function enrollmentAddIbanEnrollmentPSD2() {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_ADD_IBAN_ENROLLMENT
    };
}
exports.enrollmentAddIbanEnrollmentPSD2 = enrollmentAddIbanEnrollmentPSD2;
function enrollmentRemoveIbanEnrollmentPSD2(index) {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_REMOVE_IBAN_ENROLLMENT,
        payload: index
    };
}
exports.enrollmentRemoveIbanEnrollmentPSD2 = enrollmentRemoveIbanEnrollmentPSD2;
function enrollmentUpdateIbanEnrollmentPSD2(index, newIban) {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_UPDATE_IBAN_ENROLLMENT,
        payload: {
            index,
            newIban
        }
    };
}
exports.enrollmentUpdateIbanEnrollmentPSD2 = enrollmentUpdateIbanEnrollmentPSD2;
function enrollmentSetFormSubmitted(formSubmitted) {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_SET_FORM_ENROLLMENT_SUBMITTED,
        payload: formSubmitted
    };
}
exports.enrollmentSetFormSubmitted = enrollmentSetFormSubmitted;
function enrollmentPSD2Reset() {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_RESET_ENROLLMENT
    };
}
exports.enrollmentPSD2Reset = enrollmentPSD2Reset;
/* ENROLLMENT PRESA VISIONE PDF - INIZIO */
function enrollmentDownloadPdfEnrollmentPSD2Start() {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_DOWNLOAD_PDF_ENROLLMENT_START
    };
}
function enrollmentDownloadPdfEnrollmentPSD2Success() {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_DOWNLOAD_PDF_ENROLLMENT_SUCCESS
    };
}
const enrollmentDownloadPdfEnrollmentPSD2 = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(enrollmentDownloadPdfEnrollmentPSD2Start());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            new api_rest_1.Psd2AttivaControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt, 'application/pdf')).stampaPdfconsenso().then((response) => {
                if (extraArguments.platform === 'desktop') {
                    (0, helpers_1.hadlePdfResponse)(response, (url) => dispatch((0, general_actions_1.popUpBlockedPdf)(url)));
                }
                if (extraArguments.platform === 'mobile') {
                    (0, helpers_1.download_sharePDF)(extraArguments, 'enrollment_psd2.pdf', response, false);
                }
                dispatch(enrollmentDownloadPdfEnrollmentPSD2Success());
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.enrollmentDownloadPdfEnrollmentPSD2 = enrollmentDownloadPdfEnrollmentPSD2;
/* ENROLLMENT PRESA VISIONE PDF - FINE */
/* ENROLLMENT STATUS - INIZIO */
function consentRenewPSD2Start() {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_CONSENT_RENEW_START
    };
}
function consentRenewPSD2WebViewLink(link) {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_CONSENT_RENEW_WEBVIEW_LINK,
        payload: link
    };
}
function consentRenewPSD2Error() {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_CONSENT_RENEW_ERROR
    };
}
function consentRenewCompleted() {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_CONSENT_RENEW_SUCCESS
    };
}
// export const consentRenewPSD2 = (): ThunkAction<void, State, any, TPSD2ActionTypes> => async (dispatch, getState, extraArguments) => {
//   dispatch(setLoader(true));
//   dispatch(consentRenewPSD2Start());
//   if (extraArguments.mock) {
//     dispatch(setLoader(false));
//   } else {
//     extraArguments.getItem('jwt').then((jwt: string) => {
//       const stateElencoRapporti = getState().elencoRapporti;
//       const request: ConsentrenewRequest = {
//         accountListInDto: {
//           aspspId: stateElencoRapporti.form.rapportoSelezionato.psd2?.aspspId!,
//           aspspProductCode: stateElencoRapporti.form.rapportoSelezionato.psd2?.aspspProductCode,
//         }
//       }
//       new Psd2AttivaControllerApi(getConfiguration(Configuration, extraArguments, undefined, jwt) as Configuration).consentrenew(request).then((response: EnrollmentStatusDto) => {
//         if (response.accountList?.link) {
//           dispatch(consentRenewPSD2WebViewLink(response.accountList?.link));
//         } else {
//           dispatch(consentRenewCompleted());
//         }
//       }).catch(async (error: IGeneralState) => {
//         const decodedError = await decodeError(error);
//         if (decodedError?._links?.url?.href) {
//           dispatch(consentRenewPSD2WebViewLink(decodedError._links.url.href));
//         } else {
//           dispatch(generalError(await formatError(decodedError, false, true)));
//           dispatch(consentRenewPSD2Error());
//         }
//       }).finally(() => {
//         dispatch(setLoader(false));
//       });
//     });
//   }
// };
/* VERIFICA FIRMA DIGITALE - INIZIO */
function verificaFirmaDigitaleStart() {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.VERIFICA_FIRMA_DIGITALE_START
    };
}
function verificaFirmaDigitaleSuccess(response) {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.VERIFICA_FIRMA_DIGITALE_SUCCESS,
        payload: response
    };
}
const verificaFirmaDigitale = (tipologiaContratto, verificaEsitoFirma = false, codiceRapportoCobadged) => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(verificaFirmaDigitaleStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            const request = {
                tipologiaContratto,
                verificaEsitoFirma,
                codiceRapportoCobadged
            };
            new api_rest_1.FirmaDigitalePrivateControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).verificaContratto(request).then((response) => {
                dispatch(verificaFirmaDigitaleSuccess(response));
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.verificaFirmaDigitale = verificaFirmaDigitale;
/* VERIFICA FIRMA DIGITALE - FINE */
/* CREA CERTIFICATO DI FIRMA DIGITALE - INIZIO */
function creaCertificatoFirmaDigitaleStart() {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.CREA_CERTIFICATO_FIRMA_DIGITALE_START
    };
}
function creaCertificatoFirmaDigitaleSuccess() {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.CREA_CERTIFICATO_FIRMA_DIGITALE_SUCCESS,
    };
}
const creaCertificatoFirmaDigitale = () => async (dispatch, getState, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(creaCertificatoFirmaDigitaleStart());
    if (extraArguments.mock) {
        dispatch((0, general_actions_1.setLoader)(false));
    }
    else {
        extraArguments.getItem('jwt').then((jwt) => {
            const request = {
                certificatoInfoDto: getState().psd2.verificaFirmaDigitale.certificateInfo // faccio il cast perché i 2 dto sono identici anche se sono tipi diversi
            };
            new api_rest_1.FirmaDigitalePrivateControllerApi((0, general_configuration_1.default)(api_rest_1.Configuration, extraArguments, undefined, jwt)).creaCertificato(request).then(() => {
                dispatch(creaCertificatoFirmaDigitaleSuccess());
            }).catch(async (error) => {
                dispatch(generalError(await (0, helpers_1.formatError)(error)));
            }).finally(() => {
                dispatch((0, general_actions_1.setLoader)(false));
            });
        });
    }
};
exports.creaCertificatoFirmaDigitale = creaCertificatoFirmaDigitale;
/* CREA CERTIFICATO DI FIRMA DIGITALE - FINE */
function resetProcessoFirmaDigitale() {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.RESET_PROCESSO_FIRMA_DIGITALE
    };
}
exports.resetProcessoFirmaDigitale = resetProcessoFirmaDigitale;
function setPaymentPollingActiveAction(active) {
    return {
        type: psd2_types_1.EReduxPSD2ActionTypes.PSD2_PAYMENT_POLLING_SET_ACTIVE,
        payload: active
    };
}
exports.setPaymentPollingActiveAction = setPaymentPollingActiveAction;
const setPaymentPollingActive = (active) => async (dispatch, _, extraArguments) => {
    dispatch(setPaymentPollingActiveAction(active));
};
exports.setPaymentPollingActive = setPaymentPollingActive;
