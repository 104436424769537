"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pnsFeedback = exports.pnsSubscription = void 0;
const api_pns_1 = require("@sparkasse/api-pns");
const api_rest_1 = require("@sparkasse/api-rest");
const __1 = require("..");
const general_actions_1 = require("../general/general.actions");
const general_configuration_1 = require("../general/general.configuration");
const helpers_1 = require("../helpers");
function pnsSubscriptionStart() {
    return {
        type: __1.EReduxActionTypes.PNS_SUBSCRIPTION_START
    };
}
function pnsSubscriptionSuccess() {
    return {
        type: __1.EReduxActionTypes.PNS_SUBSCRIPTION_SUCCESS
    };
}
function generalError(value) {
    return async (dispatch) => {
        dispatch((0, general_actions_1.setError)(value));
    };
}
const pnsSubscription = (username, codiceFiscale, uniqueDeviceId, fcmToken) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(pnsSubscriptionStart());
    const request = {
        pNSSubscriptionData: {
            userId: username,
            deviceId: uniqueDeviceId,
            platform: extraArguments.platformMobile.OS === 'android' ? api_pns_1.PNSSubscriptionDataPlatformEnum.Fcm : api_pns_1.PNSSubscriptionDataPlatformEnum.Apn,
            codiceFiscale,
            registrationData: {
                registrationId: fcmToken,
                deviceOs: extraArguments.platformMobile.OS
            }
        }
    };
    extraArguments.getItem('jwt').then((jwt) => {
        new api_pns_1.SubscriptionApi((0, general_configuration_1.default)(api_pns_1.Configuration, extraArguments, '/notifications', jwt, undefined, 'pushNotification')).createSubscriptions(request).then(() => {
            dispatch(pnsSubscriptionSuccess());
        }).catch(async (error) => {
            dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    });
};
exports.pnsSubscription = pnsSubscription;
function pnsFeedbackStart() {
    return {
        type: __1.EReduxActionTypes.PNS_FEEDBACK_START
    };
}
function pnsFeedbackSuccess() {
    return {
        type: __1.EReduxActionTypes.PNS_FEEDBACK_SUCCESS
    };
}
const pnsFeedback = (deviceToken, trackingId) => async (dispatch, _, extraArguments) => {
    dispatch((0, general_actions_1.setLoader)(true));
    dispatch(pnsFeedbackStart());
    console.log('pnsFeedback richiamato');
    const request = {
        type: api_rest_1.SendNotificationFeedbackTypeEnum.crm,
        notificationFeedbackInDto: {
            deviceToken: deviceToken,
            trackingId: trackingId
        }
    };
    extraArguments.getItem('jwt').then((jwt) => {
        new api_rest_1.PublicGenericControllerApi((0, general_configuration_1.default)(api_pns_1.Configuration, extraArguments, undefined, jwt)).sendNotificationFeedback(request).then(() => {
            dispatch(pnsFeedbackSuccess());
        }).catch(async (error) => {
            dispatch(generalError(await (0, helpers_1.formatError)(error)));
        }).finally(() => {
            dispatch((0, general_actions_1.setLoader)(false));
        });
    });
};
exports.pnsFeedback = pnsFeedback;
