import InputCurrency from "component/inputCurrency/inputCurrency.component";
import InputText from "component/inputText/inputText.component";
import React, { ReactNode, useEffect, useMemo, useState } from "react";

interface Item {
  key: string;
  description: string;
  hasInputText?: boolean;
  hasInputNumber?: boolean;
  sideElement?: ReactNode;
  complexLabel?: string;
}

interface Props {
  className?: string;
  classNameItemsPrefix?: string;
  descrizione?: string;
  disable?: boolean;
  value?: string;
  items: Array<Item>;
  onChange: (value: string) => void;
  inError?: boolean;
  error?: string;
  checkedElement?: ReactNode;
  indice?: string;
}

const InputRadioGroup: React.FC<Props> = (props: Props) => {
  const {
    className,
    classNameItemsPrefix,
    descrizione,
    disable,
    value,
    items,
    onChange,
    inError,
    error,
    checkedElement,
    indice
  } = props;

  const [isInputSelected, setInputSelected] = useState<boolean>(false);

  const inputId = items.find((i) => i.hasInputNumber || i.hasInputText)?.key;
  const isFormInputSelected =
    !items.find((i) => i.key == value) && value != undefined && value != "";
  const inputValue = isFormInputSelected ? value : "";

  const descriptionLabel = useMemo(() => {
    return descrizione?.trim();
  }, [descrizione])

  const onInputChange = () => {
    setInputSelected(true);
    onChange("");
  };

  useEffect(() => {
    if (!isFormInputSelected && !!value) {
      setInputSelected(false);
      return;
    }

    setInputSelected(true);
  }, [value]);

  const onChangeRadio = (value: string) => {
    onChange(value)
  }

  return (
    <>
      <div
        id={`radiogroup-${indice}`}
        className={`radio-button-box${className ? " " + className : ""}`}
        role="radiogroup"
        aria-labelledby={`radiogroup-label-${indice}`}
      >
        {descriptionLabel && (
          <label
            id={`radiogroup-label-${indice}`}
            className={`radio-button-label m-0 ${error !== "" && inError ? "form-field__label_error" : ""
              }`}
            aria-label={descriptionLabel}
          >
            {descriptionLabel}
          </label>
        )}

        {items.map((item, i) => {
          const isInput = inputId == item.key;
          const isInputChecked =
            item.key === value || (isInput && isInputSelected);

          return (
            <div
              key={"radio_" + i}
              className={`radio-button-row${i === 0 ? " pt-2" : " pt-3"}`}
            >
              <input
                name={`radio-group-${descriptionLabel || descrizione || item.key}`}
                value={item.key}
                type="radio"
                aria-checked={isInputChecked}
                checked={isInputChecked}
                onChange={isInput ? onInputChange : () => onChangeRadio(item.key)}
                disabled={disable}
                id={`input-${item.key}-${i}-${indice}`}
                aria-describedby={inError ? `input-${item.description}-error` : undefined}
                aria-label={item.description}
                className="visually-hidden"
              />
              <div
                id={`input-label-${item.key}-${i}-${indice}`}
                className={`container-listino m-0${classNameItemsPrefix
                  ? " " + classNameItemsPrefix + item.key
                  : ""
                  }`}
              >

                <button
                  className={`radiobutton-listino${isInputChecked ? ' checked' : ''}`}
                  onClick={isInput ? onInputChange : () => onChangeRadio(item.key)}
                  aria-hidden={true}
                >

                </button>

                <div
                  className={`radiobutton-listino-description ${!!item.complexLabel ? "maxHeight" : ""
                    }`}
                >
                  <label
                    htmlFor={`input-${item.key}-${i}-${indice}`}
                    id={`label-for-${!!item.complexLabel && isInputChecked
                      ? item.complexLabel
                      : item.description}`}
                    className="description-listino m-0 font-weight-300"
                    aria-hidden={true}
                  >
                    {!!item.complexLabel && isInputChecked ? (
                      <div>
                        {item.description}
                        <div style={{ fontSize: 14 }}>{item.complexLabel}</div>
                      </div>
                    ) : (
                      item.description
                    )}
                  </label>
                </div>
              </div>

              {inputId == item.key && (
                <>
                  {item.hasInputText && (
                    <div className="radio-group-input-text">
                      <InputText
                        noHorizontalPadding
                        descrizione={""}
                        onChange={(v) => onChange(v)}
                        value={inputValue}
                      />
                    </div>
                  )}

                  {item.hasInputNumber && (
                    <div className="radio-group-input-number">
                      <InputCurrency
                        noHorizontalPadding
                        descrizione={""}
                        onChange={(v: string) => onChange(v)}
                        value={inputValue}
                        maxDecimalChar={0}
                      />
                    </div>
                  )}
                </>
              )}

              {item.sideElement}

              {isInputChecked && checkedElement}
            </div>
          );
        })}

        {inError && (
          <div className="mt-3">
            <p
              className="input-radio-error"
              id={`input-${descriptionLabel}-error`}
              role="alert"
              aria-live="assertive"
            >
              {error}
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default InputRadioGroup;
