import BankIcon from "component/Icon/icon.component";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  isGestisciPreferiti?: boolean;
  descrizione: string;
  icon: any;
  onClick: any;
  className?: string;
}

const ItemWidget: React.FC<Props> = (props: Props) => {
  const { isGestisciPreferiti, icon, descrizione, onClick, className } = props;

  const { t } = useTranslation();

  const onGetAction = () => {
    onClick && onClick();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === " ") {
      e.preventDefault();
      onGetAction();
    }
  };

  return (
    <button
      className={`quick-operation ${className || ""}`}
      onClick={() => {
        onGetAction();
      }}
      style={{ border: "none" }}
      aria-label={!isGestisciPreferiti
        ? t("accessibilita.azioniRapide.noModal", { descrizione: descrizione })
        : t("accessibilita.azioniRapide.modal", { descrizione: descrizione })}
      onKeyDown={handleKeyDown}
      role={!isGestisciPreferiti ? "link" : undefined}
    >
      <div className="iconView-item-quickOpr">
        <BankIcon
          title={icon}
        />
      </div>
      <p className="txtWidgetView-item-quickOpr">{descrizione}</p>
    </button>
  );
};

export default ItemWidget;
