import BankIcon from "component/Icon/icon.component";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  isLink?: boolean;
  img?: string
  onClick: any
  disabled?: boolean
  showImageBack?: boolean
  descrizione: string
  className?: string
  imageOnLeft?: boolean
  notRed?: boolean
  buttonOnLeft?: boolean
  height?: number
  width?: number
  imageMonochrome?: boolean
  nexiDebitPage?: boolean
}

const ButtonText2: React.FC<Props> = (props: Props) => {

  const {
    isLink,
    onClick,
    img,
    descrizione,
    className,
    showImageBack,
    imageOnLeft,
    disabled,
    notRed,
    buttonOnLeft,
    height,
    width,
    imageMonochrome,
    nexiDebitPage
  } = props;

  const { t } = useTranslation();

  return (
    <button
      className={
        `button-text${notRed ? ' button-text-not-red' : ''
        }${imageOnLeft ? ' image-on-left' : ''
        }${!!className ? ' ' + className : ''
        }${disabled ? ' disabled' : ''
        }${buttonOnLeft ? ' button-on-left' : ''
        }${nexiDebitPage ? ' button-cta-nexi-debit' : ''
        }`}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      aria-label={isLink ? t("accessibilita.buttonText2Link.vai", { descrizione: descrizione }) : descrizione}
      aria-disabled={disabled}
      role={isLink ? "link" : undefined}
    >
      <label>{descrizione}</label>
      {(img || showImageBack) &&
        <BankIcon
          width={width}
          height={height}
          title={img || "navigazione_avanti"}
          monochrome={imageMonochrome}
        />
      }
    </button>
  );
};


export default ButtonText2;
