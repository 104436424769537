"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ENexiDebitActionTypes = exports.FormNexiDebitDtoFromJSON = void 0;
const api_rest_1 = require("@sparkasse/api-rest");
function FormNexiDebitDtoFromJSON(json) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'rapportoSelezionato': !(0, api_rest_1.exists)(json, 'rapportoSelezionato') ? (0, api_rest_1.RapportoCartaNexiDtoFromJSON)({}) : json.rapportoSelezionato,
        'periodo': !(0, api_rest_1.exists)(json, 'periodo') ? '' : json.periodo,
        'dataDa': !(0, api_rest_1.exists)(json, 'dataDa') ? '' : json.dataDa,
        'dataDaError': !(0, api_rest_1.exists)(json, 'dataDaError') ? '' : json.dataDaError,
        'dataA': !(0, api_rest_1.exists)(json, 'dataA') ? '' : json.dataA,
        'dataAError': !(0, api_rest_1.exists)(json, 'dataAError') ? '' : json.dataAError,
        'importo': !(0, api_rest_1.exists)(json, 'importo') ? undefined : json.importo,
        'importoError': !(0, api_rest_1.exists)(json, 'importoError') ? '' : json.importoError,
        'esercente': !(0, api_rest_1.exists)(json, 'esercente') ? '' : json.esercente,
        'entrate': !(0, api_rest_1.exists)(json, 'entrate') ? true : json.entrate,
        'uscite': !(0, api_rest_1.exists)(json, 'uscite') ? true : json.uscite,
        'segno': !(0, api_rest_1.exists)(json, 'segno') ? undefined : json.segno,
        'ordinamento': !(0, api_rest_1.exists)(json, 'ordinamento') ? api_rest_1.CriteriRicercaMovimentiNexiDebitDtoOrdinamentoEnum.D : json.ordinamento,
        'offset': !(0, api_rest_1.exists)(json, 'offset') ? undefined : json.offset,
        'listLoader': !(0, api_rest_1.exists)(json, 'listLoader') ? false : json.listLoader
    };
}
exports.FormNexiDebitDtoFromJSON = FormNexiDebitDtoFromJSON;
var ENexiDebitActionTypes;
(function (ENexiDebitActionTypes) {
    ENexiDebitActionTypes["NEXI_DEBIT_GET_PARAMETRI_START"] = "NEXI_DEBIT_GET_PARAMETRI_START";
    ENexiDebitActionTypes["NEXI_DEBIT_GET_PARAMETRI_SUCCESS"] = "NEXI_DEBIT_GET_PARAMETRI_SUCCESS";
    ENexiDebitActionTypes["NEXI_DEBIT_DATA_DA"] = "NEXI_DEBIT_DATA_DA";
    ENexiDebitActionTypes["NEXI_DEBIT_DATA_A"] = "NEXI_DEBIT_DATA_A";
    ENexiDebitActionTypes["NEXI_DEBIT_PERIODO"] = "NEXI_DEBIT_PERIODO";
    ENexiDebitActionTypes["NEXI_DEBIT_IMPORTO"] = "NEXI_DEBIT_IMPORTO";
    ENexiDebitActionTypes["NEXI_DEBIT_ESERCENTE"] = "NEXI_DEBIT_ESERCENTE";
    ENexiDebitActionTypes["NEXI_DEBIT_OFFSET"] = "NEXI_DEBIT_OFFSET";
    ENexiDebitActionTypes["NEXI_DEBIT_LIST_LOADER"] = "NEXI_DEBIT_LIST_LOADER";
    ENexiDebitActionTypes["NEXI_DEBIT_ENTRATE_USCITE"] = "NEXI_DEBIT_ENTRATE_USCITE";
    ENexiDebitActionTypes["NEXI_DEBIT_ORDINAMENTO"] = "NEXI_DEBIT_ORDINAMENTO";
    ENexiDebitActionTypes["NEXI_DEBIT_SET_RAPPORTO_SELEZIONATO"] = "NEXI_DEBIT_SET_RAPPORTO_SELEZIONATO";
    ENexiDebitActionTypes["NEXI_DEBIT_GET_MOVIMENTI_START"] = "NEXI_DEBIT_GET_MOVIMENTI_START";
    ENexiDebitActionTypes["NEXI_DEBIT_GET_MOVIMENTI_SUCCESS"] = "NEXI_DEBIT_GET_MOVIMENTI_SUCCESS";
    ENexiDebitActionTypes["NEXI_DEBIT_RESET_FILTRO"] = "NEXI_DEBIT_RESET_FILTRO";
    ENexiDebitActionTypes["NEXI_DEBIT_RESET"] = "NEXI_DEBIT_RESET";
    ENexiDebitActionTypes["NEXI_DEBIT_RESET_PARAMETRI_ACQUISTO"] = "NEXI_DEBIT_RESET_PARAMETRI_ACQUISTO";
    ENexiDebitActionTypes["NEXI_DEBIT_RESET_MOVIMENTI"] = "NEXI_DEBIT_RESET_MOVIMENTI";
    ENexiDebitActionTypes["NEXI_DEBIT_ESPORTA_DETTAGLIO_PDF_START"] = "NEXI_DEBIT_ESPORTA_DETTAGLIO_PDF_START";
    ENexiDebitActionTypes["NEXI_DEBIT_ESPORTA_DETTAGLIO_PDF_SUCCESS"] = "NEXI_DEBIT_ESPORTA_DETTAGLIO_PDF_SUCCESS";
    ENexiDebitActionTypes["NEXI_DEBIT_ENTRATE"] = "NEXI_DEBIT_ENTRATE";
    ENexiDebitActionTypes["NEXI_DEBIT_USCITE"] = "NEXI_DEBIT_USCITE";
    ENexiDebitActionTypes["NEXI_DEBIT_ENTRATA_USCITA"] = "NEXI_DEBIT_ENTRATA_USCITA";
    ENexiDebitActionTypes["NEXI_DEBIT_ESPORTA_ELENCO_PDF_START"] = "NEXI_DEBIT_ESPORTA_ELENCO_PDF_START";
    ENexiDebitActionTypes["NEXI_DEBIT_ESPORTA_ELENCO_PDF_SUCCESS"] = "NEXI_DEBIT_ESPORTA_ELENCO_PDF_SUCCESS";
    ENexiDebitActionTypes["NEXI_DEBIT_START_PIN_VIEW__SCA_START"] = "NEXI_DEBIT_START_PIN_VIEW__SCA_START";
    ENexiDebitActionTypes["NEXI_DEBIT_START_PIN_VIEW__SCA_SUCCESS"] = "NEXI_DEBIT_START_PIN_VIEW__SCA_SUCCESS";
    ENexiDebitActionTypes["NEXI_DEBIT_GET_PIN_VIEW_START"] = "NEXI_DEBIT_GET_PIN_VIEW_START";
    ENexiDebitActionTypes["NEXI_DEBIT_GET_PIN_VIEW_SUCCESS"] = "NEXI_DEBIT_GET_PIN_VIEW_SUCCESS";
    ENexiDebitActionTypes["NEXI_DEBIT_RESET_PIN_VIEW"] = "NEXI_DEBIT_RESET_PIN_VIEW";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_GET_PARAMETRI_START"] = "NEXI_DEBIT_ACQUISTO_GET_PARAMETRI_START";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_GET_PARAMETRI_SUCCESS"] = "NEXI_DEBIT_ACQUISTO_GET_PARAMETRI_SUCCESS";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_GET_CARTE_IN_SCADENZA_START"] = "NEXI_DEBIT_ACQUISTO_GET_CARTE_IN_SCADENZA_START";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_GET_CARTE_IN_SCADENZA_SUCCESS"] = "NEXI_DEBIT_ACQUISTO_GET_CARTE_IN_SCADENZA_SUCCESS";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_CHECK_USER_START"] = "NEXI_DEBIT_ACQUISTO_CHECK_USER_START";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_CHECK_USER_SUCCESS"] = "NEXI_DEBIT_ACQUISTO_CHECK_USER_SUCCESS";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_GET_LISTE_AGGIUNTIVE_PROFESSIONE_START"] = "NEXI_DEBIT_ACQUISTO_GET_LISTE_AGGIUNTIVE_PROFESSIONE_START";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_GET_LISTE_AGGIUNTIVE_PROFESSIONE_SUCCESS"] = "NEXI_DEBIT_ACQUISTO_GET_LISTE_AGGIUNTIVE_PROFESSIONE_SUCCESS";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_CONTROLLO_START"] = "NEXI_DEBIT_ACQUISTO_CONTROLLO_START";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_CONTROLLO_SUCCESS"] = "NEXI_DEBIT_ACQUISTO_CONTROLLO_SUCCESS";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_SET_TIPO_CARTA"] = "NEXI_DEBIT_ACQUISTO_SET_TIPO_CARTA";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_SET_CODICE_RAPPORTO"] = "NEXI_DEBIT_ACQUISTO_SET_CODICE_RAPPORTO";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_SET_PROFESSIONE"] = "NEXI_DEBIT_ACQUISTO_SET_PROFESSIONE";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_SET_TAE"] = "NEXI_DEBIT_ACQUISTO_SET_TAE";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_SET_PROVENIENZA_FONDI"] = "NEXI_DEBIT_ACQUISTO_SET_PROVENIENZA_FONDI";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_SET_PERSONA_POLITICAMENTE_ESPOSTA"] = "NEXI_DEBIT_ACQUISTO_SET_PERSONA_POLITICAMENTE_ESPOSTA";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_SET_SCOPO_PREVALENTE"] = "NEXI_DEBIT_ACQUISTO_SET_SCOPO_PREVALENTE";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_SET_PRESA_VISIONE_DOCUMENTI"] = "NEXI_DEBIT_ACQUISTO_SET_PRESA_VISIONE_DOCUMENTI";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_SET_PRESA_VISIONE_DOCUMENTI_FIRMA"] = "NEXI_DEBIT_ACQUISTO_SET_PRESA_VISIONE_DOCUMENTI_FIRMA";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_SET_CONSENSO_RESPONSE_LIST"] = "NEXI_DEBIT_ACQUISTO_SET_CONSENSO_RESPONSE_LIST";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_RESET_CONTROLLO"] = "NEXI_DEBIT_ACQUISTO_RESET_CONTROLLO";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_RESET"] = "NEXI_DEBIT_ACQUISTO_RESET";
    ENexiDebitActionTypes["NEXI_DEBIT_VERIFICA_CGS_START"] = "NEXI_DEBIT_VERIFICA_CGS_START";
    ENexiDebitActionTypes["NEXI_DEBIT_VERIFICA_CGS_SUCCESS"] = "NEXI_DEBIT_VERIFICA_CGS_SUCCESS";
    ENexiDebitActionTypes["NEXI_DEBIT_ANNULLA_ACQUISTO_START"] = "NEXI_DEBIT_ANNULLA_ACQUISTO_START";
    ENexiDebitActionTypes["NEXI_DEBIT_ANNULLA_ACQUISTO_SUCCESS"] = "NEXI_DEBIT_ANNULLA_ACQUISTO_SUCCESS";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_RESET_TAE"] = "NEXI_DEBIT_ACQUISTO_RESET_TAE";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_GET_CONVENZIONE_START"] = "NEXI_DEBIT_ACQUISTO_GET_CONVENZIONE_START";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_GET_CONVENZIONE_SUCCESS"] = "NEXI_DEBIT_ACQUISTO_GET_CONVENZIONE_SUCCESS";
    ENexiDebitActionTypes["NEXI_DEBIT_START_PAN_VIEW__SCA_START"] = "NEXI_DEBIT_START_PAN_VIEW__SCA_START";
    ENexiDebitActionTypes["NEXI_DEBIT_START_PAN_VIEW__SCA_SUCCESS"] = "NEXI_DEBIT_START_PAN_VIEW__SCA_SUCCESS";
    ENexiDebitActionTypes["NEXI_DEBIT_GET_PAN_VIEW_START"] = "NEXI_DEBIT_GET_PAN_VIEW_START";
    ENexiDebitActionTypes["NEXI_DEBIT_GET_PAN_VIEW_SUCCESS"] = "NEXI_DEBIT_GET_PAN_VIEW_SUCCESS";
    ENexiDebitActionTypes["NEXI_DEBIT_RESET_PAN_VIEW"] = "NEXI_DEBIT_RESET_PAN_VIEW";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_CONCLUDI_NEGATIVAMENTE_PRATICA_ACQUISTO_START"] = "NEXI_DEBIT_ACQUISTO_CONCLUDI_NEGATIVAMENTE_PRATICA_ACQUISTO_START";
    ENexiDebitActionTypes["NEXI_DEBIT_ACQUISTO_CONCLUDI_NEGATIVAMENTE_PRATICA_ACQUISTO_SUCCESS"] = "NEXI_DEBIT_ACQUISTO_CONCLUDI_NEGATIVAMENTE_PRATICA_ACQUISTO_SUCCESS";
    ENexiDebitActionTypes["NEXI_DEBIT_COPY_PAN_VIEW"] = "NEXI_DEBIT_COPY_PAN_VIEW";
    ENexiDebitActionTypes["NEXI_DEBIT_SET_LISTA_CARTE_IN_SCADENZA"] = "NEXI_DEBIT_SET_LISTA_CARTE_IN_SCADENZA";
    ENexiDebitActionTypes["NEXI_DEBIT_SET_INIZIO_FLUSSO_CARTE_IN_SCADENZA"] = "NEXI_DEBIT_SET_INIZIO_FLUSSO_CARTE_IN_SCADENZA";
    ENexiDebitActionTypes["NEXI_DEBIT_SET_COBADGE_DA_RINNOVARE"] = "NEXI_DEBIT_SET_COBADGE_DA_RINNOVARE";
    ENexiDebitActionTypes["NEXI_DEBIT_RESET_RINNOVO_COBADGE"] = "NEXI_DEBIT_RESET_RINNOVO_COBADGE";
})(ENexiDebitActionTypes = exports.ENexiDebitActionTypes || (exports.ENexiDebitActionTypes = {}));
