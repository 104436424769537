import BankIcon from "component/Icon/icon.component";
import TourContestuale from "component/TourContestuale/TourContestuale";
import React, { ReactNode, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  children?: ReactNode;
  isVisible: boolean;
  setVisible?: (v: boolean) => void;
  hideTour?: boolean;
}

const RightbarShoulder = (props: Props) => {
  const { children, isVisible, setVisible, hideTour } = props;

  const { t } = useTranslation();

  const spallaRef = useRef<HTMLDivElement | null>(null);
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);

  const trapFocus = (e: KeyboardEvent) => {
    if (e.key === "Tab" && spallaRef.current) {
      const focusableElements = spallaRef.current.querySelectorAll(
        'a, button, input, [tabindex]:not([tabindex="-1"])'
      ) as NodeListOf<HTMLElement>;

      if (focusableElements.length === 0) return;

      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      if (e.shiftKey && document.activeElement === firstElement) {
        e.preventDefault();
        lastElement.focus();
      } else if (!e.shiftKey && document.activeElement === lastElement) {
        e.preventDefault();
        firstElement.focus();
      }
    }
  };

  useEffect(() => {
    if (isVisible) {
      document.addEventListener("keydown", trapFocus);

      if (closeButtonRef.current) {
        closeButtonRef.current.focus();
      }
    } else {
      document.removeEventListener("keydown", trapFocus);
    }

    return () => {
      document.removeEventListener("keydown", trapFocus);
    };
  }, [isVisible]);

  return (
    <>
      <aside
        aria-label={t("accessibilita.component.pannelloLaterale")}
        aria-hidden={!isVisible}
      >

        <div
          className={`spalla-destra-background ${isVisible ? "" : "spalla-destra-chiusa"}`}
          onClick={() => setVisible && setVisible(false)}
        ></div>

        <div className={`spalla-destra ${isVisible ? "" : "spalla-destra-chiusa"}`} ref={spallaRef}>
          <div
            className="close-button-container"
          >
            <button
              className="close-button"
              ref={closeButtonRef}
              onClick={() => setVisible && setVisible(false)}
              aria-label={t("accessibilita.pannelloLaterale.close")}>
              <BankIcon
                title={"navigazione_chiudi"}
              />
            </button>
          </div>
          <div className="spalla-destra-content">
            {children}
            {!hideTour && <TourContestuale />}
          </div>


        </div>
      </aside>
    </>
  );
};

export default RightbarShoulder;
