import { CriteriRicercaMovimentiRequestIn, CriteriRicercaMovimentiRequestInOrdinamentoEnum, getMovimenti, getMovimentiHomepage, movimentiHomepage, MovimentoDto, parametriMovimenti, rapportiNoPSD2, resetMovimentiHomepage, salvaWidgets, widgetSaldo, widgetsUtente } from "@sparkasse/commons";
import ButtonText2 from "component/buttonText2/buttonText2.component";
import Card from "component/cards/card.component";
import { navigateToPage, WEBPAGE } from "component/history/history.component";
import BankIcon from "component/Icon/icon.component";
import SelectRapportoMini, { IRapporto } from "component/SelectRapportoMini/SelectRapportoMini";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from 'react-loading-skeleton';
import { connect } from "react-redux";
import { AppState } from "reducers";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { currencyFormat, dateFormat, formatRapporto, smartDateFormat } from "../../helpers/utilities";

export const WIDGET_MOVIMENTI = "menu.saldo_e_movimenti";

const MESI_SU_CONTO_SELEZIONATO = 3;
const NUMERO_MAX_MOVIMENTI = 5;

const Movements: React.FC<Props> = (props) => {

  const { t } = useTranslation();

  const onClickMovimento = (item: MovimentoDto) => {
    navigateToPage(WEBPAGE.SaldoEMovimenti.url, {
      conto: item.strumento,
      numeroOperazione: item.numeroOperazione
    });
  };

  const onNavigateToSaldoEMovimenti = (conto: number | undefined) => {
    navigateToPage(WEBPAGE.SaldoEMovimenti.url, {
      conto
    });
  };

  const widget = props.widgetsUtente?.widgets?.find(w => w.widgetId === WIDGET_MOVIMENTI);
  const contoSelezionatoCompleto = props.parametriMovimenti.rapporti?.find(r => r.rapporto?.iban === widget?.option?.codicerapporto);

  useEffect(() => {
    if (props.widgetsUtente?.widgets && props.parametriMovimenti.rapporti) {
      const movimentiPresenti = !!props.movimenti.movimenti?.length;
      props.resetMovimentiHomepage();
      const _iban = props.widgetsUtente?.widgets?.find(w => w.widgetId === WIDGET_MOVIMENTI)?.option?.codicerapporto;
      const contoSelezionato =
        props.parametriMovimenti.rapporti?.find(r => r.rapporto?.iban === _iban) ||
        (props.parametriMovimenti.rapporti?.length === 1 ? props.parametriMovimenti.rapporti[0] : undefined);
      if (contoSelezionato?.rapporto?.id) {
        const _criteriPsd2 = contoSelezionato.rapporto.psd2 ? {
          accountId: contoSelezionato.rapporto.psd2.accountId,
          providerId: contoSelezionato.rapporto.psd2.providerId,
        } : undefined;
        const filtro: CriteriRicercaMovimentiRequestIn = {
          codiceRapporto: contoSelezionato?.rapporto?.id,
          dataIniziale: moment().subtract(MESI_SU_CONTO_SELEZIONATO, "months").toDate(),
          dataFinale: moment().toDate(),
          ordinamento: CriteriRicercaMovimentiRequestInOrdinamentoEnum.D,
          criteriPsd2: _criteriPsd2
        }
        props.getMovimenti(filtro, true, true);
      } else {
        // loader solo se si tratta di refresh
        props.getMovimentiHomepage(true, movimentiPresenti);
      }
    }
  }, [props.widgetsUtente?.widgets, props.parametriMovimenti.rapporti]);

  const itemsRapporti = props.parametriMovimenti.rapporti?.map(r => ({
    id: (r.rapporto?.iban || "-1").toString(),
    description: formatRapporto(r),
    descriptionClosed: (r.rapporto?.alias || r.rapporto?.intestazione || r.rapporto?.nomeBanca) + ' - ' + r.rapporto?.iban
  })) || [];

  const items: Array<IRapporto> = contoSelezionatoCompleto && props.parametriMovimenti.rapporti?.length === 1
    ? itemsRapporti
    : [{
      id: "0",
      description: `${t('general.carousel.tuttiIContri')}`
    }].concat(itemsRapporti);

  const selezionaConto = (item: IRapporto) => {
    if (item.id !== (contoSelezionatoCompleto?.rapporto?.id || 0).toString()) {
      const _widgetSaldo = props.widgetSaldo ? [props.widgetSaldo] : [];
      props.salvaWidgets((props.widgetsUtente?.widgets?.map(w => w.widgetId === WIDGET_MOVIMENTI ? {
        ...w,
        option: {
          toggle: true,
          interval: 0,
          codicerapporto: item.id
        }
      } : w) || []).concat(_widgetSaldo), true);
    }
  };

  return (
    <div className={"widget movements-list-home movements-clickable"} >

      <h2 className="widget-title">
        {t('home.movements.ultimiMovimenti')}
      </h2>

      <div className="underline-short" />

      <SelectRapportoMini
        id="movements"
        items={items}
        selectedItem={contoSelezionatoCompleto?.rapporto?.iban || "0"}
        onSelect={selezionaConto}
      />

      {!!props.movimenti?.alertPsd2?.length &&
        <div className="widget-errore-psd2">
          <div className="widget-errore-psd2-icon">
            <BankIcon title="alert" keepSize />
          </div>
          <div className="widget-errore-psd2-testo">
            <span>{t('psd2Attiva.erroreMovimenti')}</span>
          </div>
        </div>
      }

      {(!props.movimenti || !props?.movimenti?.movimenti) &&
        <Skeleton height={80} count={5} />
      }
      {props.movimenti?.movimenti?.length === 0 &&
        <div className="movements-list-home-non-disponibili">{t('home.movements.notAvailable')}</div>
      }

      <div>
        {props.movimenti?.movimenti?.slice(0, NUMERO_MAX_MOVIMENTI).map((mov, i) => (
          <Card
            key={"mov-" + i}
            descrizione={mov.descrizione}
            id={i}
            data={smartDateFormat(mov.dataContabile)}
            dataValuta={dateFormat(mov.dataValuta)}
            importo={currencyFormat(mov.totale)}
            categoria={mov.categoriaPfm?.label}
            img={mov.categoriaPfm?.cssId}
            sign={mov.segno}
            account={(mov.psd2 && !!mov.descBanca ? (mov.descBanca + " - ") : "") + (mov.rapportoLight ?
              ((mov.rapportoLight?.alias || mov.rapportoLight?.intestazione || "") + " - " + (mov.rapportoLight?.ibanFormattato || "")) :
              mov.descrizioneAggiuntiva)}
            item={mov}
            apriDettaglio={onClickMovimento}
          />
        ))}
      </div>

      <ButtonText2
        className="widget-vai-a-dettaglio"
        onClick={() => onNavigateToSaldoEMovimenti(contoSelezionatoCompleto?.rapporto?.id)}
        img="navigazione_avanti"
        descrizione={t("home.saldo_e_movimenti")}
        isLink
      />

    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  movimenti: movimentiHomepage(state.movimenti),
  parametriMovimenti: parametriMovimenti(state.movimenti),
  rapportiNoPSD2: rapportiNoPSD2(state.movimenti),
  widgetsUtente: widgetsUtente(state.widgets),
  widgetSaldo: widgetSaldo(state.widgets)
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getMovimentiHomepage,
      getMovimenti,
      salvaWidgets,
      resetMovimentiHomepage
    },
    dispatch
  );

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Movements);
