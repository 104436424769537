import { OperazioneOneClickDto, OperazioniOneClickDto, PayloadDto } from "@sparkasse/commons";
import ButtonText2 from "component/buttonText2/buttonText2.component";
import history, { WEBPAGE } from "component/history/history.component";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ItemOneclick from "../../component/itemOneclick/oneclick.component";

const navigateToDispositiva = (
  idOneClickCollegata: number,
  payload: PayloadDto | undefined,
  codiceFunzione: string | undefined
) => {
  let path = "";
  switch (codiceFunzione) {
    case "BONIFICOSEPA":
      path = "/bonifico-sepa";
      break;
    case "CARTACONTODISPOCATEGORIE":
      path = "/ricarica-smartCard";
      break;
    case "BOLLETTINOPOSTALE":
      path = "/bollettino-bianco";
      break;
    case "RICARICACELLULARE":
      path = "/ricarica-telefonica";
      break;
    case "SUDTIROLPASS":
      path = WEBPAGE.RicaricaAltoAdigePass.url;
      break;
    default:
      path = "/home";
      break;
  }
  if (payload?.disposizione) {
    (payload.disposizione as any).idOneClickCollegata = idOneClickCollegata;
  }
  history.push({
    pathname: path,
    state: {
      payload,
    },
  });
};

interface Props {
  operazioni: OperazioniOneClickDto
  maxOperazioniToShow: number
}

const Oneclick: React.FC<Props> = (propsIn: Props) => {

  const { t } = useTranslation();

  const {
    operazioni,
    maxOperazioniToShow
  } = propsIn;

  const [operazioniDaMostrare, setOperazioniDaMostrare] = useState<OperazioneOneClickDto[]>([]);

  useEffect(() => {

    if (operazioni.operazioniOneClick) {
      setOperazioniDaMostrare(operazioni.operazioniOneClick.filter((operazione, index) => !!operazione && index < maxOperazioniToShow));
    }

  }, [operazioni, maxOperazioniToShow]);

  return (
    <div className="one-click" tabIndex={0}>
      <h2 tabIndex={0} className="text-one-click">{t('personalizzaHomepage.operazioni1click.titolo')}</h2>
      <label tabIndex={0} className="text-one-click-description">
        {t("home.oneClick.descrizione")}
      </label>
      {
        operazioniDaMostrare && operazioniDaMostrare
          .map(operazione => {
            return (
              <ItemOneclick descrizione={`${operazione.payload?.alias}`} key={operazione.id} click={() => navigateToDispositiva(operazione.id || -1, operazione.payload, operazione.codiceFunzione)} />
            )
          })
      }
      <ButtonText2
        img="azioni_aggiungi"
        descrizione={t("home.oneClick.aggiungi")}
        className="oneclick-aggiungi"
        onClick={() => {
          history.push("/operazioni-one-click", { creaNuova: true });
        }}
      />
    </div>
  );
};

export default Oneclick;
