"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OperationLogDtoToJSON = exports.OperationLogDtoFromJSONTyped = exports.OperationLogDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function OperationLogDtoFromJSON(json) {
    return OperationLogDtoFromJSONTyped(json, false);
}
exports.OperationLogDtoFromJSON = OperationLogDtoFromJSON;
function OperationLogDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceUtente': !runtime_1.exists(json, 'codiceUtente') ? undefined : json['codiceUtente'],
        'codiceFunzione': !runtime_1.exists(json, 'codiceFunzione') ? undefined : json['codiceFunzione'],
        'evento': !runtime_1.exists(json, 'evento') ? undefined : json['evento'],
        'esito': !runtime_1.exists(json, 'esito') ? undefined : json['esito'],
        'noteAggiuntive': !runtime_1.exists(json, 'noteAggiuntive') ? undefined : json['noteAggiuntive'],
        'datiDispositiva': !runtime_1.exists(json, 'datiDispositiva') ? undefined : json['datiDispositiva'],
        'infoAutenticazione': !runtime_1.exists(json, 'infoAutenticazione') ? undefined : json['infoAutenticazione'],
    };
}
exports.OperationLogDtoFromJSONTyped = OperationLogDtoFromJSONTyped;
function OperationLogDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceUtente': value.codiceUtente,
        'codiceFunzione': value.codiceFunzione,
        'evento': value.evento,
        'esito': value.esito,
        'noteAggiuntive': value.noteAggiuntive,
        'datiDispositiva': value.datiDispositiva,
        'infoAutenticazione': value.infoAutenticazione,
    };
}
exports.OperationLogDtoToJSON = OperationLogDtoToJSON;
