import React from 'react';
import { useTranslation } from 'react-i18next';
import loader from "../../assets/image/loader.gif";

const Loader = () => {

  const { t } = useTranslation();

  return (
    <div
      className="loader"
      role="status"
    >
      <img
        className="loader_img"
        src={loader}
        alt={t("accessibilita.loader.active")}
      />
    </div>
  );
};

export default Loader;
