"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnnulloPraticaAcquistoDtoToJSON = exports.AnnulloPraticaAcquistoDtoFromJSONTyped = exports.AnnulloPraticaAcquistoDtoFromJSON = void 0;
const runtime_1 = require("../runtime");
function AnnulloPraticaAcquistoDtoFromJSON(json) {
    return AnnulloPraticaAcquistoDtoFromJSONTyped(json, false);
}
exports.AnnulloPraticaAcquistoDtoFromJSON = AnnulloPraticaAcquistoDtoFromJSON;
function AnnulloPraticaAcquistoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'codiceRapporto': !runtime_1.exists(json, 'codiceRapporto') ? undefined : json['codiceRapporto'],
        'flussoCoBadged': !runtime_1.exists(json, 'flussoCoBadged') ? undefined : json['flussoCoBadged'],
        'codiceRapportoCoBadged': !runtime_1.exists(json, 'codiceRapportoCoBadged') ? undefined : json['codiceRapportoCoBadged'],
        'pan': !runtime_1.exists(json, 'pan') ? undefined : json['pan'],
    };
}
exports.AnnulloPraticaAcquistoDtoFromJSONTyped = AnnulloPraticaAcquistoDtoFromJSONTyped;
function AnnulloPraticaAcquistoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'codiceRapporto': value.codiceRapporto,
        'flussoCoBadged': value.flussoCoBadged,
        'codiceRapportoCoBadged': value.codiceRapportoCoBadged,
        'pan': value.pan,
    };
}
exports.AnnulloPraticaAcquistoDtoToJSON = AnnulloPraticaAcquistoDtoToJSON;
