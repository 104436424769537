"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Core Banking Services
 * API core - Cassa di risparmio di Bolzano
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecuperaDocumentiContrattoTipoContrattoEnum = exports.CompilaContrattoTipologiaContrattoEnum = exports.FirmaDigitalePublicControllerApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class FirmaDigitalePublicControllerApi extends runtime.BaseAPI {
    /**
     * Servizio per la compilazione dei contratti e apertura di una stanza di firma.
     */
    compilaContrattoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.tipologiaContratto === null || requestParameters.tipologiaContratto === undefined) {
                throw new runtime.RequiredError('tipologiaContratto', 'Required parameter requestParameters.tipologiaContratto was null or undefined when calling compilaContratto.');
            }
            if (requestParameters.datiFirmatarioDto === null || requestParameters.datiFirmatarioDto === undefined) {
                throw new runtime.RequiredError('datiFirmatarioDto', 'Required parameter requestParameters.datiFirmatarioDto was null or undefined when calling compilaContratto.');
            }
            const queryParameters = {};
            if (requestParameters.verificaEsitoFirma !== undefined) {
                queryParameters['verificaEsitoFirma'] = requestParameters.verificaEsitoFirma;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/system/firmadigitale/contratto/{tipologiaContratto}/compila`.replace(`{${"tipologiaContratto"}}`, encodeURIComponent(String(requestParameters.tipologiaContratto))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.DatiFirmatarioDtoToJSON(requestParameters.datiFirmatarioDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.EsitoCompilaContrattoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Servizio per la compilazione dei contratti e apertura di una stanza di firma.
     */
    compilaContratto(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.compilaContrattoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Recupera la lista di documenti compilati associati al contratto.
     */
    recuperaDocumentiContrattoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.tipoContratto === null || requestParameters.tipoContratto === undefined) {
                throw new runtime.RequiredError('tipoContratto', 'Required parameter requestParameters.tipoContratto was null or undefined when calling recuperaDocumentiContratto.');
            }
            if (requestParameters.datiFirmatarioDto === null || requestParameters.datiFirmatarioDto === undefined) {
                throw new runtime.RequiredError('datiFirmatarioDto', 'Required parameter requestParameters.datiFirmatarioDto was null or undefined when calling recuperaDocumentiContratto.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/system/firmadigitale/contratto/{tipoContratto}/documenti`.replace(`{${"tipoContratto"}}`, encodeURIComponent(String(requestParameters.tipoContratto))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.DatiFirmatarioDtoToJSON(requestParameters.datiFirmatarioDto),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.DocumentoContrattoDtoFromJSON));
        });
    }
    /**
     * Recupera la lista di documenti compilati associati al contratto.
     */
    recuperaDocumentiContratto(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.recuperaDocumentiContrattoRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.FirmaDigitalePublicControllerApi = FirmaDigitalePublicControllerApi;
/**
    * @export
    * @enum {string}
    */
var CompilaContrattoTipologiaContrattoEnum;
(function (CompilaContrattoTipologiaContrattoEnum) {
    CompilaContrattoTipologiaContrattoEnum["ADDENDUM"] = "ADDENDUM";
    CompilaContrattoTipologiaContrattoEnum["QUESTIONARIO_MIFID"] = "QUESTIONARIO_MIFID";
    CompilaContrattoTipologiaContrattoEnum["ATTIVAZIONE_ON"] = "ATTIVAZIONE_ON";
    CompilaContrattoTipologiaContrattoEnum["QUESTIONARIO_ADEGUATA_VERIFICA"] = "QUESTIONARIO_ADEGUATA_VERIFICA";
    CompilaContrattoTipologiaContrattoEnum["CGS"] = "CGS";
    CompilaContrattoTipologiaContrattoEnum["MODIFICA_MASSIMALI"] = "MODIFICA_MASSIMALI";
    CompilaContrattoTipologiaContrattoEnum["ACQUISTO_NEXI_DEBIT"] = "ACQUISTO_NEXI_DEBIT";
    CompilaContrattoTipologiaContrattoEnum["ACQUISTO_CO_BADGED"] = "ACQUISTO_CO_BADGED";
})(CompilaContrattoTipologiaContrattoEnum = exports.CompilaContrattoTipologiaContrattoEnum || (exports.CompilaContrattoTipologiaContrattoEnum = {}));
/**
    * @export
    * @enum {string}
    */
var RecuperaDocumentiContrattoTipoContrattoEnum;
(function (RecuperaDocumentiContrattoTipoContrattoEnum) {
    RecuperaDocumentiContrattoTipoContrattoEnum["ADDENDUM"] = "ADDENDUM";
    RecuperaDocumentiContrattoTipoContrattoEnum["QUESTIONARIO_MIFID"] = "QUESTIONARIO_MIFID";
    RecuperaDocumentiContrattoTipoContrattoEnum["ATTIVAZIONE_ON"] = "ATTIVAZIONE_ON";
    RecuperaDocumentiContrattoTipoContrattoEnum["QUESTIONARIO_ADEGUATA_VERIFICA"] = "QUESTIONARIO_ADEGUATA_VERIFICA";
    RecuperaDocumentiContrattoTipoContrattoEnum["CGS"] = "CGS";
    RecuperaDocumentiContrattoTipoContrattoEnum["MODIFICA_MASSIMALI"] = "MODIFICA_MASSIMALI";
    RecuperaDocumentiContrattoTipoContrattoEnum["ACQUISTO_NEXI_DEBIT"] = "ACQUISTO_NEXI_DEBIT";
    RecuperaDocumentiContrattoTipoContrattoEnum["ACQUISTO_CO_BADGED"] = "ACQUISTO_CO_BADGED";
})(RecuperaDocumentiContrattoTipoContrattoEnum = exports.RecuperaDocumentiContrattoTipoContrattoEnum || (exports.RecuperaDocumentiContrattoTipoContrattoEnum = {}));
