import { findPage } from "component/history/history.component";
import BankIcon, { ICON_ALIAS } from "component/Icon/icon.component";
import ItemQuickOpr from "component/itemQuickOpr/quickOpr.component";
import ModalPreferitiComponent, { OperazionePreferita } from "component/modalPreferiti/modalPreferiti.component";
import { sendEventToMatomo } from "helpers/utilities";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  goTo: (path: string) => void
  operazioniPreferiteCorrenti: string[] | undefined
  getOperazioniPreferiti: Function
  onOpenSpallaDestra?: () => void
}

const OperazioniPreferite: React.FC<Props> = (props) => {

  const { t } = useTranslation();

  const {
    goTo,
    operazioniPreferiteCorrenti,
    getOperazioniPreferiti,
    onOpenSpallaDestra
  } = props;

  const [showModal, setShowModal] = useState(false);

  const preferitiBuilder = (id: string, order: number) => ({
    id: id,
    descrizione: t(id),
    icon: id,
    path: findPage(id)?.url || "",
    order: order
  });

  useEffect(() => {
    getOperazioniPreferiti();
  }, []);

  const naviga = (op: OperazionePreferita) => {
    sendEventToMatomo('NAVIGAZIONE_DA_PREFERITI', `navigazione verso ${op.path}`)
    goTo(op.path);
  };

  const operazioni: OperazionePreferita[] = operazioniPreferiteCorrenti?.map((f, i) => (preferitiBuilder(f, i))) || [];

  return (
    <nav
      className="quick-operations-container"
      aria-label={t("accessibilita.navigazione.azioniRapide")}
    >

      <div className="quick-operations">

        {operazioni?.map(op =>
          <ItemQuickOpr
            key={op.id}
            descrizione={op.descrizione}
            icon={op.icon && ICON_ALIAS[op.icon] ? op.icon : "varie"}
            onClick={() => {
              naviga(op)
            }}
          />
        )}

        <ItemQuickOpr
          key={"quickOperations.modifica"}
          className="quick-op-stile-alternativo"
          descrizione={t("home.quickOperations.descrizione")}
          icon={"navigazione_preferiti_off"}
          onClick={() => { setShowModal(true) }}
          isGestisciPreferiti
        />

        <ModalPreferitiComponent
          show={showModal}
          onClose={() => { setShowModal(false) }}
          items={operazioni}
        />

      </div>

      {onOpenSpallaDestra &&
        <div className="trigger-spalla-destra">
          <BankIcon
            title={"spalla-destra"}
            tooltip={t("common.apriSpallaDestra")}
            onClick={onOpenSpallaDestra}
          />
        </div>
      }

    </nav>
  );
};

export default OperazioniPreferite;
