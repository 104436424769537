import { bilancio, CriteriBilancioIn, getBilancio, rapportiNoPSD2, salvaWidgets, widgetSaldo, widgetsUtente } from "@sparkasse/commons";
import ButtonText2 from "component/buttonText2/buttonText2.component";
import { navigateToPage, WEBPAGE } from "component/history/history.component";
import SelectRapportoMini, { IRapporto } from "component/SelectRapportoMini/SelectRapportoMini";
import { currencyFormat, formatRapporto } from "helpers/utilities";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../reducers";

export const WIDGET_BILANCIO = "menu.bilancio";

const navigateToBilancio = () => {
  navigateToPage(WEBPAGE.Bilancio.url);
};

const WidgetBilancio = (props: Props) => {

  const { t } = useTranslation();

  const {
    bilancio,
    rapportiNoPSD2,
    widgetsUtente,
    widgetSaldo,
    getBilancio,
    salvaWidgets
  } = props;

  const widget = widgetsUtente?.widgets?.find(w => w.widgetId === WIDGET_BILANCIO);
  const contoSelezionatoCompleto = rapportiNoPSD2?.find(r => r.rapporto?.iban === widget?.option?.codicerapporto);
  const [tooltipEntrateVisible, setTooltipEntrateVisible] = useState(false);
  const [tooltipUsciteVisible, setTooltipUsciteVisible] = useState(false);

  useEffect(() => {
    const filtro: CriteriBilancioIn = {
      codiceRapporto: contoSelezionatoCompleto?.rapporto?.id,
      dataIniziale: moment().subtract(moment().date() - 1, "days").toDate(),
      dataFinale: moment().toDate()
    };
    getBilancio(filtro);
  }, [contoSelezionatoCompleto]);

  const items: Array<IRapporto> = [{
    id: "0",
    description: `${t('general.carousel.tuttiIContri')}`
  }].concat(rapportiNoPSD2?.map(r => ({
    id: (r.rapporto?.iban || "-1").toString(),
    description: formatRapporto(r),
    descriptionClosed: (r.rapporto?.alias || r.rapporto?.intestazione) + ' - ' + r.rapporto?.iban
  })) || []);

  const selezionaConto = (item: IRapporto) => {
    if (item.id !== (contoSelezionatoCompleto?.rapporto?.id || 3).toString()) {
      const _widgetSaldo = widgetSaldo ? [widgetSaldo] : [];
      salvaWidgets((widgetsUtente?.widgets?.map(w => w.widgetId === WIDGET_BILANCIO ? {
        ...w,
        option: {
          toggle: true,
          interval: 0,
          codicerapporto: item.id
        }
      } : w) || []).concat(_widgetSaldo));
    }
  }

  const getSign = (amount: number | undefined) => {
    return (amount && amount > 0) ? "+" : "";
  };

  const renderTooltip = (txt: string) => {
    return (
      <Tooltip id="button-tooltip">
        {txt}
      </Tooltip>
    );
  };

  const sommaValoreAssolutoOperazioni: number = useMemo(() => {
    if (
      bilancio &&
      bilancio.entrateTrimestre !== undefined &&
      bilancio.usciteTrimestre !== undefined
    ) {
      return Math.abs(bilancio.entrateTrimestre) + Math.abs(bilancio.usciteTrimestre)
    }
    return 1;
  }, [bilancio]);

  const flexEntrate = bilancio?.entrateTrimestre ? (bilancio.entrateTrimestre / sommaValoreAssolutoOperazioni).toFixed(2) : 0;
  const flexUscite = bilancio?.usciteTrimestre ? (Math.abs(bilancio.usciteTrimestre) / sommaValoreAssolutoOperazioni).toFixed(2) : 0;

  return (
    <div className="widget widget-bilancio">

      <p className="widget-title">
        {t("home.widgets.bilancio.title")} {t(`common.months.${moment().month() + 1}`)} {moment().format("YYYY")}
      </p>

      <div className="underline-short" />

      <SelectRapportoMini
        items={items}
        selectedItem={contoSelezionatoCompleto?.rapporto?.iban || "0"}
        onSelect={selezionaConto}
      />

      <div className="chartView-widget-bilancio">
        <div className="titleChartView-widget-bilancio">
          <p className={`txtAmountChart-widget-bilancio is-saldo ${(bilancio.sommaTrimestre || 0) >= 0 ? "entrate-color" : "uscite-color"}`}>
            {getSign(bilancio.sommaTrimestre)}
            {currencyFormat(bilancio.sommaTrimestre, (bilancio.divisa || "").replace("EUR", "€"))}
          </p>
          <p className="txtTitleChart-widget-bilancio">
            {t("home.widgets.bilancio.descrizione")}
          </p>
        </div>
      </div>

      {/* Per evitare modifiche troppo impattanti, ho aggiunto un tabIndex per ottenere il focus
 evitando di trasformare il div in un button */}
      <div className={`barView-widget-bilancio${flexEntrate == 0 && flexUscite == 0 ? ' is-chart-empty' : ''}`}>
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip('+' + currencyFormat(bilancio?.entrateTrimestre))}
          show={tooltipEntrateVisible}
          onToggle={(isOpen) => setTooltipEntrateVisible(isOpen)}
        >
          <div
            aria-label={t("home.widgets.bilancio.accessibilita.entrate", { valore: currencyFormat(bilancio.entrateTrimestre) })}
            tabIndex={0}
            className="entrate-bar-view"
            style={{
              flex: flexEntrate
            }}
            onFocus={() => setTooltipEntrateVisible(true)}
            onBlur={() => setTooltipEntrateVisible(false)}
            onMouseEnter={() => setTooltipEntrateVisible(true)}
            onMouseLeave={() => setTooltipEntrateVisible(false)}
          />
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(currencyFormat(bilancio?.usciteTrimestre))}
          show={tooltipUsciteVisible}
          onToggle={(isOpen) => setTooltipUsciteVisible(isOpen)}
        >
          <div
            aria-label={t("home.widgets.bilancio.accessibilita.uscite", { valore: currencyFormat(bilancio.usciteTrimestre) })}
            tabIndex={0}
            className={`uscite-bar-view${flexEntrate == 0 ? '' : ' has-separator'}`}
            style={{
              flex: flexUscite
            }}
            onFocus={() => setTooltipUsciteVisible(true)}
            onBlur={() => setTooltipUsciteVisible(false)}
            onMouseEnter={() => setTooltipUsciteVisible(true)}
            onMouseLeave={() => setTooltipUsciteVisible(false)}
          />
        </OverlayTrigger>
      </div>

      <div className="legendView1-widget-bilancio">

        <div className="legendView1col1-widget-bilancio">
          <p className="txtAmountLegend-widget-bilancio is-saldo entrate-color">
            {getSign(bilancio.entrateTrimestre)}
            {currencyFormat(bilancio.entrateTrimestre, (bilancio.divisa || "").replace("EUR", "€"))}
          </p>
          <p className="txtTitleLegend-widget-bilancio">{t("home.widgets.bilancio.entrate")}</p>
        </div>

        <div className="legendView1col2-widget-bilancio">
          <p className="txtAmountLegend-widget-bilancio is-saldo uscite-color">
            {currencyFormat(bilancio.usciteTrimestre, (bilancio.divisa || "").replace("EUR", "€"))}
          </p>
          <p className="txtTitleLegend-widget-bilancio">{t("home.widgets.bilancio.uscite")}</p>
        </div>

      </div>

      <ButtonText2
        className="widget-vai-a-dettaglio"
        onClick={navigateToBilancio}
        img="navigazione_avanti"
        descrizione={t("home.widgets.bilancio.dettaglio")}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  bilancio: bilancio(state.bilancio),
  rapportiNoPSD2: rapportiNoPSD2(state.movimenti),
  widgetsUtente: widgetsUtente(state.widgets),
  widgetSaldo: widgetSaldo(state.widgets)
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getBilancio,
      salvaWidgets
    },
    dispatch
  );

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(WidgetBilancio);
