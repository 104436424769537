import BankIcon from "component/Icon/icon.component";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export enum RegexType {
  complete = "complete",
  restricted = "restricted",
  integer = "integer",
  digits = "digits",
  description = "description",
  email = "email",
}

const regex: { [key in RegexType]: { typed: RegExp; paste: RegExp } } = {
  [RegexType.complete]: {
    typed: /^[\s0-9a-zA-ZáàäéèíìóòöúùüÀÁÄÈÉÌÍÒÓÖÙÚÜß/?:().,'+\-&]+$/,
    paste: /[^\s0-9a-zA-ZáàäéèíìóòöúùüÀÁÄÈÉÌÍÒÓÖÙÚÜß/?:().,'+\-&]/g,
  },
  [RegexType.restricted]: {
    typed: /^[\s0-9a-zA-Z]+$/,
    paste: /[^\s0-9a-zA-Z]/g,
  },
  [RegexType.integer]: {
    typed: /^(0|[1-9][0-9]*)$/,
    paste: /[^0-9]/g, // TODO non elimina gli zeri iniziali
  },
  [RegexType.digits]: {
    typed: /^[0-9]+$/,
    paste: /[^0-9]/g,
  },
  [RegexType.description]: {
    typed: /^[\s0-9a-zA-ZáàäéèíìóòöúùüÀÁÄÈÉÌÍÒÓÖÙÚÜß']+$/,
    paste: /[^\s0-9a-zA-ZáàäéèíìóòöúùüÀÁÄÈÉÌÍÒÓÖÙÚÜß']/g,
  },
  [RegexType.email]: {
    typed: /^[\s0-9a-zA-Z.-@]+$/,
    paste: /[^\s0-9a-zA-Z.-@]/g,
  },
};

interface Props {
  regexType?: RegexType;
  descrizione: string;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  value?: string;
  errore?: string;
  img?: any;
  ariaLabelImg?: string;
  imgOnClick?: any;
  icon?: string;
  className?: string;
  outlineImg?: any;
  outlineImgTooltip?: string;
  outlineImgOnClick?: any;
  maxChar?: number;
  inError?: boolean;
  disable?: boolean;
  placeholder?: string;
  noHorizontalPadding?: boolean;
  labelStyle?: React.CSSProperties;
  inputStyle?: string;
}

const InputText: React.FC<Props> = (props: Props) => {
  const {
    regexType = RegexType.complete,
    descrizione,
    onChange,
    onFocus,
    onBlur,
    value,
    errore,
    img,
    ariaLabelImg,
    imgOnClick,
    icon,
    className,
    outlineImg,
    outlineImgTooltip,
    outlineImgOnClick,
    maxChar,
    inError,
    disable,
    placeholder,
    noHorizontalPadding,
    inputStyle,
    labelStyle,
  } = props;
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [focus, setFocus] = useState<boolean>(false);

  const { t } = useTranslation();

  let classNameInput = "form-field__input"; // form-field__input__disable
  if (img) {
    classNameInput += " form-field__input_btn";
  }
  if (disable) {
    classNameInput += " form-field__input__disable";
  }

  useEffect(() => {
    setFocus(!!value);
  }, [value]);

  const onInputFocus = () => {
    !value && setFocus(true);
    onFocus?.();
  };

  const onInputBlur = () => {
    !value && setFocus(false);
    onBlur?.();
  };

  return (
    <div className={`input-control input-control-text ${className || ""}`}>
      <div className="form-field">
        <div className="form-field__control">
          <label
            htmlFor={`input-${descrizione}`}
            style={labelStyle}
            className={`
            form-field__label${errore !== "" && inError ? " form-field__label_error" : ""
              }
            ${focus && (!value || value === "") && !placeholder
                ? " form-field__label_focus"
                : ""
              }
            ${focus || disable ? " form-field__label_focus" : ""}
          `}
          >
            {descrizione}
          </label>
          <input
            type="text"
            maxLength={maxChar}
            className={`${classNameInput} ${inputStyle}`}
            disabled={disable ? disable : false}
            placeholder={placeholder && focus ? placeholder : ""}
            value={value ? value : disable ? "-" : ""}
            onChange={(e: any) => {
              const re = regex[regexType].typed;
              if (e.target.value == "" || re.test(e.target.value)) {
                onChange?.(e.target.value);
              }
            }}
            style={{
              ...(noHorizontalPadding && { paddingRight: 0, paddingLeft: 0 }),
            }}
            title={disable && value ? value : ""}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            onPaste={(e: any) => {
              e.preventDefault();
              const indexStartPosition = e.target.selectionStart;
              const indexEndPosition = e.target.selectionEnd;
              let paste = e.clipboardData.getData("text");
              const re = regex[regexType].paste;
              paste = unescape(paste.replace(re, "")).trim();
              const valore = !!value
                ? [
                  value.slice(0, indexStartPosition),
                  paste,
                  value.slice(indexEndPosition),
                ].join("")
                : paste;
              onChange?.(valore.substring(0, maxChar));
            }}
            id={`input-${descrizione}`}
            aria-describedby={`input-${descrizione}-error`}
          />

          <div className="form-field__bar"></div>
          {/* {img && (<img className="input-control-text-icon" src={img} onClick={imgOnClick || undefined} />)} */}
          {icon && (
            <BankIcon className="input-control-text-icon" title={icon} />
          )}
        </div>
        {img && (
          <button
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={imgOnClick}
            aria-label={ariaLabelImg}
          >
            <img
              className="input-control-iban--image-info"
              src={img}
              onClick={imgOnClick}
              alt=""
            />
          </button>
        )}

        {outlineImg && (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip
                id="button-tooltip">{outlineImgTooltip || ""}
              </Tooltip>
            }
            show={tooltipVisible}
            onToggle={(isOpen) => setTooltipVisible(isOpen)}
          >
            <button
              role="tooltip"
              className="info-tooltip-trigger"
              aria-label={t(outlineImgTooltip ?? '')}
              onFocus={() => setTooltipVisible(true)}
              onBlur={() => setTooltipVisible(false)}
              onMouseEnter={() => setTooltipVisible(true)}
              onMouseLeave={() => setTooltipVisible(false)}
            >
              <img
                className="input-control-iban--image-info"
                src={outlineImg}
                onClick={outlineImgOnClick}
                alt=""
              />
            </button>
          </OverlayTrigger>
        )}
      </div>

      {inError && (
        <p
          className="input-control-error"
          id={`input-${descrizione}-error`}
          role="alert"
        >
          {errore}
        </p>
      )}
    </div>
  );
};

export default InputText;
